import {  useContext, useState } from "react";
import DataLabel from "./DataLabel";
import { MfctContext } from "../context";


function FormDataLabel({data, natures, getDatas,closeCallback}){

  const {project} = useContext(MfctContext )

const update_data = async () => {

  if( document.getElementById("label_data"))
    { data.label = document.getElementById("label_data").value;
    
  }


      var result = await project.api("/update_data", { body:{data_id : data.data_id, label : data.label } })
      console.log(result)
    
      await getDatas();
      closeCallback();
    

  }


return             <div className="p-2 flex flex-col w-full ">


<span>Label </span>
    <input id="label_data" className='w-full p-1 border-2 rounded border-gray-500 border ' placeholder='Saisir Label' defaultValue={data.label} />
   <button className='m-2 p-1  rounded-full font-bold text-white bg-blue-500' onClick={update_data}>{"Enregistrer "} </button>
<button className="m-2 p-1 px-2 rounded-full  text-white bg-red-500 font-bold" onClick={closeCallback} > Annuler</button>
</div>



}


export default FormDataLabel;