import { useMemo, useState, useEffect, useContext } from "react";
import DataLabel from "./DataLabel";
import ChooseNature from "./ChooseNature";
import FormRef from "./FormRef";
import Geohash from 'latlon-geohash';
import { MfctContext } from "../context";

function FormAddData({ parent, defaultData, datas, natures, getNatures, setPathData, closeCallback }) {

  const {project} = useContext(MfctContext)
  const [data, setData] = useState(defaultData);
  const [canModNature, setCanModNature] = useState(true);
  const [formRefOpened, setFormRefOpened] = useState([])


  const dataSpecifique = useMemo(() => {
if( !parent ) return null;
    var _Ds = [parent, ...datas.filter(d => parent.path.split(d.path).length == 2)]
    for (var i = 0; i < _Ds.length; i++) {
      var d = _Ds[i];
      var nature = natures.find(n => n.nature_id == d.nature_id);
      if (nature?.is_specificity) {
        d.nature = nature
        return d;
      }

    }

    return null;
  }, [parent, natures])


  const nature = useMemo(() => {
    return natures && data.nature_id ? natures.find(n => data.nature_id == n.nature_id) : null

  }, [data])

  const isFromCollection = (struct) => {

    var parts = struct.key.split(".")
    parts.pop()
    var path = parts.join(".");
    var parent = nature.content.structure[path];
    return parent && parent.is_collection ? parent : null;


  }

  const setKey = (key, dataRef) => {
    data[key] = dataRef
    console.log("Ref", dataRef)
    setData({ ...data })
    console.log(data)
  }

  const add_data = async () => {


    data.geohash = Geohash.encode(52.20, 0.12, 12)

    if (document.getElementById("label_data")) {
      data.label = document.getElementById("label_data").value;

    }
if(nature?.content?.structure)
   {  var structs = Object.values(nature.content.structure);
    for (var i = 0; i < structs.length; i++) {
      var struct = structs[i];
      for (var j = 0; j < RequiredCount(struct);j++){
      if (document.getElementById(struct.key+"."+ j+".Label")) {
        data[struct.key +"."+ j] = { label :document.getElementById(struct.key +"."+ j+".Label").value}
      }
    }
    }
  }


    var result = await project.api("/add_data", { body: { ...data, parent } })
    console.log(result)

    await setPathData(result.path);
    closeCallback();


  }

  const nbDataPathChild = () => {
    return !datas ? 0 : datas.filter(data => !parent || data.parent_id == parent.data_id).length
  }

  const dataPathParentLabel = () => {
    return !datas || !parent ? "" : datas.find(data => !data.is_collection && data.data_id == parent.parent_id)?.label ?? ""
  }
  const dataNatureLabel = () => {
    return natures && data.nature_id ? natures.find(n => data.nature_id == n.nature_id).label : null
  }

  const RequiredCount = (struct) => {

    if (struct.is_collection) return 1;
    var structParent = isFromCollection(struct)
    if (!structParent && canModNature) return 1;
    if(struct.defaultLabel)return 1;
    console.log(structParent);
    return parseInt(structParent.requiredCount ?? 0);

  }

  const setNatureCollectionSelect = (nature, is_collection, is_reference) => {

    setData({ ...data, nature_id: nature.nature_id, is_collection: is_collection, is_reference: is_reference })

  }

  const saveNature = async () => {


    var structs = Object.values(nature.content.structure);
    for (var i = 0; i < structs.length; i++) {
      var struct = structs[i];
      if (document.getElementById(struct.key + "_requiredcount")) {
        struct["requiredCount"] = document.getElementById(struct.key + "_requiredcount").value
      }
      if (document.getElementById(struct.key + "_maxcount")) {
        struct["maxCount"] = document.getElementById(struct.key + "_maxcount").value
      }

      if (document.getElementById(struct.key + "_required")) {
        struct["required"] = document.getElementById(struct.key + "_required").checked
      }
      if (document.getElementById(struct.key + "_defaultLabel")) {
        struct["defaultLabel"] = document.getElementById(struct.key + "_defaultLabel").value
      }

      nature.content.structure[struct.key] = struct
    }


    var result = await project.api("/update_nature", { body: nature })
    getNatures();

  }



  return <div className="p-4 flex flex-col w-full ">
    <span><DataLabel data={{ label: " AJOUT DE   " + (dataNatureLabel() ?? "DATA") + (data.is_collection ? "S" : ""), is_collection: data.is_collection, nature_id: data.nature_id }} natures={natures} /></span>

    {!data.nature_id ?
      <ChooseNature natures={natures} project={project} setNature={setNatureCollectionSelect} updateNatures={getNatures} dataSpecifique={dataSpecifique} />
      :
      <>
        {(data.is_collection || !nature.content?.dynamic_label) &&
          <><span>Label </span>
            <input id="label_data" className='w-full p-1 border-2 rounded border-gray-500 border ' placeholder='Saisir Label' defaultValue={( dataNatureLabel()) + " " + (nbDataPathChild() + 1)} />
          </>
        }
        {!data.is_collection && nature && nature.content?.structure && Object.values(nature.content.structure).map(struct =>
          <div className="p-0 flex flex-col w-full " >

            {Array(RequiredCount(struct)).fill(struct).map((str, i) =>

              <DataLabel data={{ ...str, label: str.label ?? natures.find(n => n.nature_id == struct.nature_id).label + (!isFromCollection(struct) ? "" : " " + (i + 1)) }} nonature={true} natures={natures} key={i}>



                {!struct.is_collection && struct.is_reference &&
                  <div className="p-2 flex flex-col w-full border-t border-black border-opacity-10 font-bold" >
                    <div onClick={() => setFormRefOpened([...formRefOpened, str.key + "." + i])} className=" flex flex-row w-full font-bold" >{

                      data[str.key + "." + i] ? data[str.key + "." + i].label : "-"}
                      <span className="font-normal"   >{" -  CHANGER " + (str.label ?? natures.find(n => n.nature_id == struct.nature_id).label) + " >"}</span></div>

                    {formRefOpened.find(f => f == str.key + "." + i) && <FormRef project={project} getNatures={getNatures} natures={natures} path={parent.path} valideRef={(dataRef) => setKey(str.key + "." + i, dataRef)} defaultData={{ nature_id: struct.nature_id }}
                      closeCallback={() => { setFormRefOpened(formRefOpened.filter(key => key !== str.key + "." + i)); console.log(formRefOpened) }} />
                    }       </div>

                }
                {!struct.is_collection && !struct.is_reference && !struct.defaultLabel &&
                  <div className="p-2 flex flex-col w-full border-t border-black border-opacity-10 " ><span className="text-xs">Label</span>
                    <input type="text" id={str.key +"."+ i +  ".Label"} className="text-black w-full p-1" placeholder="Saisir le label " /></div>

                }
                {canModNature &&

                  <div className="p-2 flex flex-col w-full border-t border-black border-opacity-10 " >
                    {struct.is_collection && <><span className="text-xs">RequiredCount</span>
                      <input type="number" id={str.key + "_requiredcount"} className="text-black w-8 p-1" placeholder="0" defaultValue={struct.requiredCount} /></>
                    }
                    {struct.is_collection && <><span className="text-xs">MaxCount</span>
                      <input type="number" id={str.key + "_maxcount"} className="text-black w-8 p-1" placeholder="-" defaultValue={struct.maxCount} /></>
                    }
                    {!isFromCollection(struct) && !struct.is_collection && <><span className="text-xs">Required</span>
                      <input type="checkbox" id={str.key + "_required"} className="text-black w-6 p-1" defaultChecked={struct.required} /></>
                    }
                    {!struct.is_collection && !struct.is_reference && <><span className="text-xs">DefaultLabel</span>
                      <input type="text" id={str.key + "_defaultLabel"} className="text-black w-full p-1" placeholder="Saisir une valeur par défaut" defaultValue={struct.defaultLabel} /></>
                    }

                  </div>
                }
              </DataLabel>

            )
            }

          </div>)
        }
        <button className='m-2 p-1  rounded-full font-bold text-white bg-blue-500' onClick={() => saveNature()}>{"Save Nature "} </button>
        <button className="m-2 p-1  rounded-full mt-1 text-green-500 font-bold" onClick={add_data}> Ajouter</button>




      </>

    }

    <button className="m-2 p-1 px-2 rounded-full  text-white bg-red-500 font-bold" onClick={closeCallback} > Annuler</button>
  </div>



}


export default FormAddData;