


import Live from "./Live";
import { getAuthUser } from "./service/mftc-api";




function Matchs({  }) {

  return (
    <Live  owner_id={ getAuthUser().user_id   }  />
  );
}

export default Matchs;
