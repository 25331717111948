


import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from 'react';
import { projectInit, setToken, getAuthUser } from './service/mftc-api';
import Footer from './components/Footer';
import ButtonApi from './components/ButtonApi';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as fa from '@fortawesome/free-solid-svg-icons'




function Designs({  }) {
  const navigate = useNavigate();

  const [project, setProject] = useState(null);
  const [hasAccount, setHasAccount] = useState(true);

  const [activeEndpoint, setActiveEndpoint] = useState(null)
  const [paramsEndpoint, setParamsEndpoint] = useState(null)
  const [activeEndpointReady, setActiveEndpointReady] = useState(false)
  const [pendingCode, setPendingCode] = useState(false)
const [errorMessage,setErrorMessage]=useState();
const [email,setEmail]=useState();

  async function getProject() {
    console.log("getProject", process.env.REACT_APP_MFTC_SERVER_URL, process.env.REACT_APP_PROJECT_NAME)
    const p = await projectInit(process.env.REACT_APP_MFTC_SERVER_URL, process.env.REACT_APP_PROJECT_NAME)
    console.log('project', p);

    setProject(p);

  }

  useEffect(() => {
    getProject();
  }, [])


const valideMail=  (email)=>{
  var expressionReguliere = /^(([^<>()[]\.,;:s@]+(.[^<>()[]\.,;:s@]+)*)|(.+))@(([[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}])|(([a-zA-Z-0-9]+.)+[a-zA-Z]{2,}))$/;

return expressionReguliere.test(email)
}

  const signin_mail = async () => {
var emailV = document.getElementById("email").value
if(!valideMail(emailV))
{
  setErrorMessage("Adresse mail non valide")
  return 
}
    setEmail(emailV );
    if (emailV) {
      var result = await project.api("/signin_mail", { body: { email:emailV } })
   if (result.user_id)
   {
setPendingCode(true)
   }
   else 
   {
setErrorMessage(result.error)
   }

    }
  }

  const resend_code = async () => {
   
          var result = await project.api("/signin_mail", { body: { email } })
       if (result.user_id)
       {
    setPendingCode(true)
       }
       else 
       {
    setErrorMessage(result.error)
       }
    
        }
      
  const sign_activation_code = async () => {
    var  activation_code= ""
for(var i = 0; i <4;i++){
  activation_code+= document.getElementById("c"+i).value
  if(activation_code.length<i+1){
    document.getElementById("c"+i).focus()
    return 
  }
}

if( activation_code.length==4 )
{for(var i = 0; i <4;i++)  activation_code+= document.getElementById("c"+i).value=""
    var result = await project.api("/sign_activation_code", { body: { email ,activation_code  } })
    if (result && result.token) {
      setToken(result.token, result.user);
      navigate ("/");

    }
    else   { setErrorMessage(result.error)
    }
  }
  }


  const signup_mail = async () => {

    var emailV = document.getElementById("email").value
    if(!valideMail(emailV))
{
  setErrorMessage("Adresse mail non valide")
  return 
}
var username = document.getElementById("username").value;
if(username.length<4)
{
  setErrorMessage("Le pseudonyme doit faire plus de 3 caractères")
  return 
}

    setEmail(emailV );
   
   
    if (emailV && username) {
      var result = await project.api("/signup_mail", { body: { username, email:emailV } })
      if (result.user_id)
   {
setPendingCode(true)
   }
   else 
   {
setErrorMessage(result.error)
   }

    }
  }

  return (
    <>
<div className="fixed text-white font-bold p-0 px-0 w-full  flex justify-start items-center  bg-zinc-800 z-20">
  <a   href='/'><FontAwesomeIcon  icon={fa.faArrowLeft} className='text-white text-xl mr-1  px-9 py-8 ' /></a>
        <a href="/"   className='text-3xl   italic font-bold'  style={ {} }  >    <span>Live</span><span>my</span><span  className='text-red-700 ' >Game</span><span className='text-red-700 text-4xl mb-4' >!</span></a>

      </div>
      <div className=" flex justify-center items-center flex-col  min-h-screen bg-zinc-800  w-screen text-white   ">

        <div className=" w-full  ">

          {!pendingCode ?
            <div className="  flex flex-col  justify-center items-center">


              
                <span className='mb-6 text-xl'>CREATION DE MATCH </span> 

              <div className='flex flex-col justify-start w-2/3'>
                <span className='p-1'>Adresse email</span>
                <input id="email" className=' rounded-lg  p-1 w-full bg-transparent border border-white  ' type="email" />
              </div>
 <div className='flex flex-col justify-start w-2/3 m-1'>
                  <span className='p-1'>Nom d'utilisateur</span>
                  <input id="username" className=' rounded-lg  p-1 w-full bg-transparent border border-white  ' type="text" />
                </div>
              
              {
                errorMessage && <div onClick={()=>setErrorMessage(null)} className=" bg-red-800 text-white w-2/3  flex flex-col justify-center  items-center p-2 my-2 rounded-lg">
                  <div className="w-full flex justify-end"><FontAwesomeIcon icon={fa.faClose }/></div>
                  <FontAwesomeIcon icon={fa.faWarning }/><span  >{errorMessage} </span></div>
              }


                  <ButtonApi className="bg-white text-zinc-900 w-1/2 p-2" project={project} endpoint={`/signin_mail`} title={" Valider l'adresse  "} callback={signin_mail} /> 
                  <ButtonApi className="bg-white text-zinc-900 w-1/2 p-2" endpoint={`/signup_mail`} project={project} title={" Valider l'inscription  "} callback={signup_mail} />
              



            </div>
            :
            <div className='flex flex-col  justify-center items-center'>



              <div className='flex flex-col justify-center w-1/2'>
                <span className='p-1  text-center '>Saisir le code à 4 chiffres</span>
                <div className='flex flex-row justify-center w-full'>
                  {Array(4).fill({}).map((d,i) => <input id={"c"+i} className=' rounded-lg  m-1 p-1 w-12 h-16 bg-transparent border border-gray-600 text-center text-3xl' defaultValue="" placeholder='_' type="text" onChange={sign_activation_code}/>)

                  }</div>

              </div>
              <span className='underline italic  p-4 text-sm'  onClick={resend_code}   > Renvoyer le code    </span>
            </div>
          }

        </div>

      </div>
      <Footer />
    </>
  );
}

export default Designs;
