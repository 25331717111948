


import { Link, useNavigate } from "react-router-dom";
import { useEffect, useMemo, useState } from 'react';
import { projectInit, setToken, getAuthUser } from './service/mftc-api';
import Footer from './components/Footer';
import ButtonApi from './components/ButtonApi';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as fa from '@fortawesome/free-solid-svg-icons'
import DataLabel from "./components/DataLabel";
import Geohash from "latlon-geohash";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import 'dayjs/locale/fr';
import dayjs, { Dayjs } from 'dayjs';

import FormMap from "./components/FormMap";
import Loader from "./components/Loader";
import { isMobile } from "react-device-detect";

const OFFEST_LOCAL_TIMEDATE=(new Date()).getTimezoneOffset()*60000;

function SetMatch({ }) {
  const navigate = useNavigate();
  const [data, setData] = useState({ 
    start_at:(new Date(Date.now() + 0)).toISOString().substring(0, 16), 
      end_at: (new Date(Date.now() + 1000 * 60 * 60 * 24)).toISOString().substring(0, 16)})
  const [project, setProject] = useState(null);
  const [authUser, setAuthUser] = useState(getAuthUser());
  const [design, setDesign] = useState(null)
  const [natures, setNatures] = useState(null);
  const [designs, setDesigns] = useState([]);
  const [errorMessage, setErrorMessage] = useState();
  const [chooseMap, setChooseMap] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [startNow, setStartNow] = useState();
  const [startHere, setStartHere] = useState(null);
  const [currentGeoHash, setCurrentGeoHash] = useState();
  const [latlng, setLatLng] = useState();
  const [checkRequired, setCheckRequired] = useState({})
  async function getProject() {
    console.log("getProject", process.env.REACT_APP_MFTC_SERVER_URL, process.env.REACT_APP_PROJECT_NAME)
    const p = await projectInit(process.env.REACT_APP_MFTC_SERVER_URL, process.env.REACT_APP_PROJECT_NAME)
    console.log('project', p);

    setProject(p);

  }


  useEffect(() => {

    if (isMobile || true) {
      navigator.geolocation.getCurrentPosition(function (position) {

        var newG = Geohash.encode(position.coords.latitude, position.coords.longitude, 12)
        setLatLng({ lat: position.coords.latitude, lng:  position.coords.longitude})
        if (newG != currentGeoHash)
          setCurrentGeoHash(newG);




      })
    }
  }, [])




  async function setStartShow(value, end) {

    console.log(value.toISOString())
    var d = { ...data, start_at: value.toISOString().substring(0, 16) }
    if (end) d.end_at = end.toISOString().substr(0, 16)
    setData(d)

  }
  async function setEndShow(value) {
    setData({ ...data, end_at: value.toISOString().substring(0, 16) })
  }


  async function getNatures() {
    if (!project)
      return

    var result = await project.api("/get_natures")

    setNatures(result);

  }

  useEffect(() => {
    getNatures();

  }, [project]);

  const getDesigns = async () => {
    var result = await project.api("/get_designs", { body: { is_public: true } })
    setDesigns(result);
    console.log("Designs ", result);
  }

  const setGeoHash = async (body) => {

  
    var result = await project.api("/set_geohash", { body })
    setData({ ...data, ...result });
    setCheckKey("geohash", true);
    console.log("Hash ", result);
  }

  const nature = useMemo(() => {

    console.log("nature", design?.nature)
    return design?.nature

  }, [design])

  useEffect(() => {

    if (design) {

      setData({ nature_id: design.nature_id, is_live: 1 })

    }

  }, [design])

  useEffect(() => {

    if (project) {

      getDesigns()

    }

  }, [project])


  const canSave = useMemo(() => {


    var objs = document.querySelectorAll(".check_key");
    if(objs.length==0) return false 
    var result = true;
    objs.forEach(o => result = result && checkRequired[o.id])

    return result;

  }, [checkRequired, design])

  const CheckMark = ({ k }) => {
   console.log("checkRequired", checkRequired,k,checkRequired[k])
    return <FontAwesomeIcon id={k} icon={fa.faCheck} className={" check_key ml-4  text-xl " + (checkRequired[k] ? " text-green-600" : "  text-zinc-600")} />

  }


  const setCheckKey = (key, value) => {
    var d = {};
    d[key] = value;
    setCheckRequired({ ...checkRequired, ...d })

  }



  useEffect(() => {
    getProject();
  }, [])


  const RequiredCount = (struct) => {

    if (struct.is_collection) return 1;
    var structParent = isFromCollection(struct)
    if (!structParent) return 1;
    console.log(structParent);
    return parseInt(("0" + structParent.requiredCount) ?? 0);

  }

  const isFromCollection = (struct) => {

    var parts = struct.key.split(".")
    parts.pop()
    var path = parts.join(".");
    var parent = nature.content.structure[path];
    return parent && parent.is_collection ? parent : null;


  }



  const set_match = async () => {


if(!canSave) return;

    if (nature?.content?.structure) {
      var structs = Object.values(nature.content.structure);
      for (var i = 0; i < structs.length; i++) {
        var struct = structs[i];
        for (var j = 0; j < RequiredCount(struct); j++) {
          if (document.getElementById(struct.key + "." + j + ".Label")) {

            if (!document.getElementById(struct.key + "." + j + ".Label").value) {
              setErrorMessage(" Informations manquantes ");

              return;
            }


            data[struct.key + "." + j] = { label: document.getElementById(struct.key + "." + j + ".Label").value }
          }
        }
      }
    }
    if (!data.start_at) {
      setErrorMessage(" Date et heure de début manquantes ")
      return;
    }


    if (!data.end_at) {
      setErrorMessage(" Date et heure de fin manquantes ")
      return;
    }



    if (!data.geohash) {
      setErrorMessage(" Lieu manquant ")
      return;
    }
    setShowLoader(true);
    var show = await project.api("/set_match", { body: { ...data } })
    console.log(show)
    setShowLoader(false);
    navigate("/matchs");



  }

  


  return (
    <>
      {showLoader && <Loader />}
      {chooseMap && <FormMap close={() => setChooseMap(false)} valideGeoHash={(geohash, lat, lng) => setGeoHash({ geohash, lat, lng })} geohash={data.geohash ?? currentGeoHash} />}
      <div className="fixed text-white font-bold p-0 px-0 w-full  flex justify-start items-center  bg-zinc-800 z-20">
        <a href='/'><FontAwesomeIcon icon={fa.faArrowLeft} className='text-white text-xl mr-1  px-9 py-8 ' /></a>
        <a href="/" className='text-3xl   italic font-bold' style={{}}  >    <span>Live</span><span>my</span><span className='text-red-700 ' >Game</span><span className='text-red-700 text-4xl mb-4' >!</span></a>

      </div>
      <div className=" flex justify-start pt-40 items-center flex-col  min-h-screen bg-zinc-800  w-screen text-white   ">

        <div className=" w-full  pb-40">

          <div className="  flex flex-col  justify-start items-center">

            <span className='mb-6 text-xl'>CREATION DE MATCH </span>

            <div className='flex flex-col justify-start w-2/3'>
              {designs?.filter(d => !design || design.design_id == d.design_id).map(d =>

                <div className={" p-4 border-b border-zinc-400  " + (design ? " font-bold " : "")} onClick={() => setDesign(d.design_id != design?.design_id ? d : null)}   >{d.label}  </div>
              )}
            </div>

            {nature && nature.content?.structure &&

              <div className='flex flex-col justify-start w-5/6 mt-10'>

                {Object.values(nature.content.structure).map((struct, i) =>
                  <div className="p-0 flex flex-col w-full " key={i}  >

                    {Array(RequiredCount(struct)).fill(struct).filter(s => !struct.is_collection && !s.defaultLabel).map((str, i) =>

                      <div key={i}>

                        <span className="p-2 pb-0 flex flex-col w-full border-t border-black border-opacity-10 " >{(str.label ?? natures.find(n => n.nature_id == struct.nature_id).label + (!isFromCollection(struct) ? "" : " " + (i + 1))).replace("_", " ")}</span>

                        {true &&

                          <div className="py-2 flex flex-row w-full border-t border-black border-opacity-10 items-center" >

                            <input type="text" id={str.key + "." + i + ".Label"} className="text-white bg-transparent w-full p-2 border-b   border-zinc-500    " placeholder="Saisir le label "
                              onChange={() => {
                                setCheckKey(str.key + "." + i + ".Label", document.getElementById(str.key + "." + i + ".Label").value?.length > 2)

                              }}
                            />

                            <CheckMark k={str.key + "." + i + ".Label"} />
                          </div>
                        }
                      </div>

                    )
                    }

                  </div>)
                }

                <div className=' w-full flex flex-row justify-end items-center' >
                  <span className={"  text-right   my-1 border p-2 rounded m-2 " + (startNow === true ? " bg-white text-zinc-800" : " border-zinc-500 text-white")}
                    onClick={() => { setCheckKey("start", true); setStartShow(new Date(Date.now() - OFFEST_LOCAL_TIMEDATE), (new Date(Date.now() + 1000 * 60 * 60 * 24-OFFEST_LOCAL_TIMEDATE ))); setStartNow(true); }} > Ça commence !</span>
                  <span className={"  text-right   my-1 border p-2 rounded m-2   flex flex-row items-center  " + (startNow === false ? " bg-white text-zinc-800" : " border-zinc-500 text-white")} onClick={() => setStartNow(false)}  >
                    <FontAwesomeIcon icon={fa.faCalendar} className=' m-1 text-xl' />    Agenda </span>
                  <CheckMark k={"start"} />
                </div>
                {startNow === false &&
                  <>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr" >




                      <div className=' m-1 w-full bg-white  rounded  '>

                        <DateTimePicker className=' p-2 m-1 w-full text-white ' defaultValue={data.start_at ? dayjs(data.start_at) : null} label="DÉBUT" onAccept={(newValue) => setStartShow(newValue)} />

                      </div>



                      <div className=' m-1 w-full  bg-white rounded'>

                        <DateTimePicker className=' p-2 m-1 w-full text-white ' label="FIN" defaultValue={data.end_at ? dayjs(data.end_at) : null} onAccept={(newValue) => setEndShow(newValue)} />

                      </div>
                    </LocalizationProvider>
                  </>
                }

                <div className=' w-full flex flex-row justify-end items-center' >

                  {currentGeoHash &&
                    <span className={"  text-right   my-1 border p-2 rounded m-2 " + (startHere === true ? " bg-white text-zinc-800" : " border-zinc-500 text-white")} onClick={() => { setGeoHash({ geohash:currentGeoHash,lat:latlng.lat, lng:latlng.lng });setStartHere(true); }} >  Ça se passe ici !</span>
                  }
                  <div onClick={() => { setChooseMap(true); setStartHere(false) }}
                    className={"  text-right   my-1 border p-2 rounded m-2  flex flex-row items-center " + (startHere === false ? " bg-white text-zinc-800" : " border-zinc-500 text-white")}   >
                    <FontAwesomeIcon icon={fa.faEarth} className=' m-1 text-xl' /> {data.geocontent ? data.geocontent.city : "Lieu"}  </div>
                  <CheckMark k={"geohash"} />
                </div>
              </div>
            }


            {
              errorMessage && <div onClick={() => setErrorMessage(null)} className=" bg-red-800 text-white w-2/3  flex flex-col justify-center  items-center p-2 my-2 rounded-lg">
                <div className="w-full flex justify-end"><FontAwesomeIcon icon={fa.faClose} /></div>
                <FontAwesomeIcon icon={fa.faWarning} /><span  >{errorMessage} </span></div>
            }




            { design && <ButtonApi className={ " w-1/2 p-2 mt-4 "+ (canSave ? " bg-green-600 text-white ":"bg-white text-zinc-900")   } project={project} endpoint={`/set_match`} title={" Créer match "} callback={set_match} />

            }



          </div>



        </div>

      </div>
    </>
  );
}

export default SetMatch;
