

import { on } from 'events';
import {  useMemo, useState } from 'react';




function    SwitchButton ({ value, changeValue,classNameBarOff,classNameBarOn,  classNameOff,classNameOn, size })  {


const [blnOn,setblnOn] = useState(value===1)

const switchValue= ()=>{

  setblnOn(!blnOn);
  if (changeValue) changeValue(!value     ?  1:0);
}

size = size ?? 16;

    return <div className={'flex flex-row w-16  mx-1'}   onClick={switchValue}>
      <div className={'  w-16 h-8 rounded-full  border-4 border-zinc-800 ' + (blnOn ? '  bg-white ' + classNameBarOn: '  bg-zinc-800 ' +classNameBarOff)}  ></div>
      <div className={' w-8 h-8 rounded-full border-2 border-zinc-800 bg-white transition-all duration-300 ' + (blnOn ? ' -ml-8 ' +classNameOn:' -ml-16 ' +classNameOff) }></div>
    </div>
  //else 
  //return <span >{endpoint} </span>
} 

export default SwitchButton;
