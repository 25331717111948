import { useMemo, useState, useEffect, useContext } from "react";
import DataLabel from "./DataLabel";
import ChooseNature from "./ChooseNature";
import { MfctContext } from "../context";

function FormRef({ valideRef, path, defaultData, natures, getNatures, closeCallback, getDatas }) {

  const { project } = useContext(MfctContext)
  const [data, setData] = useState(defaultData);
  const [refDatas, setRefDatas] = useState(null);

  const nature = useMemo(() => {
    return natures && data.nature_id ? natures.find(n => data.nature_id == n.nature_id) : null

  }, [data])

  async function getDatasByNature() {
    console.log("getDatasByNature");
    if (!project || !data)
      return

    var result = await project.api("/get_datas_bynature/:path/:nature_id", { args: { path, nature_id: data.nature_id } })
    console.log("setRefDatas", result);
    setRefDatas(result);

  }

  const chooseRef = async (dataRef) => {
    console.log("chooseRef", dataRef)

    var data0 = { ...data, label: dataRef.label, reference_id: dataRef.data_id, content: { ...data.content, pathRef: dataRef.path } }
    setData(data0)
    console.log("data", data0)
    if (valideRef) valideRef(data0);
    else if (data.data_id) {


      var result = await project.api("/update_data", { body: { data_id: data.data_id, label: dataRef.label, reference_id: dataRef.data_id, content: { ...data.content, pathRef: dataRef.path } } })
      getDatas();

    }
    closeCallback();
  }


  useEffect(() => {
    if (data.nature_id)
      getDatasByNature()
  }, [data])




  return <div className="p-4 flex flex-col w-full ">



    <div className="p-2 border-1  border-black border-opacity-50 "> CHOIX  { } </div>

    {!data.nature_id ?
      <ChooseNature natures={natures} project={project} setNature={(nature) => { setData({ ...data, nature_id: nature.nature_id }) }} updateNatures={getNatures} />
      : <>
        {refDatas && <>
          {
            refDatas.filter((d, i) => !d.reference_id && !d.is_collection).map(dataRef =>
              <div className="border-t border-slate-300 p-1 pl-6 w-full  flex flex-col" key={dataRef.data_id}>

                <div className=" p-0 pl-6 w-full  flex flex-row" >

                  <span className=' w-full p-0' onClick={() => chooseRef(dataRef)}> <DataLabel data={dataRef} natures={natures} nonature={true} noRef={true} /></span>

                </div>
              </div>
            )
          }
        </>}

      </>
    }
    <button className="m-2 p-1 px-2 rounded-full  text-white bg-red-500 font-bold" onClick={closeCallback} > Annuler</button>
  </div>;



}


export default FormRef;