

import { useMemo } from 'react';
import { Link } from 'react-router-dom';




function Comments({ comments, className, computedComments, setShowUser, authUser, project, standAlone, isReporter }) {


    const showComment = (index) => {


        var comment = comments[index];
        if (comment.message) return true;
        var str = "" + parseInt(comment.label) == "NaN"  ? comment.label : computedComments["status_step_" + comment.report_id] + computedComments["status_comment_" + comment.report_id]
if( str+""=="NaN" ) return false ;
console.log("Comment ", str)
        for (var i = index - 1; i >= 0; i--) {
            if (!comments[i].message && str == ("" + parseInt(comments[i].label) == "NaN" ? comments[i].label : computedComments["status_step_" + comments[i].report_id] + computedComments["status_comment_" + comments[i].report_id]))
            {
                console.log("Comment Ignored", index,i ,comment, comments[i])
                return false;
            }
        }
        return true
    }


    return <div className={" mb-24 w-full flex flex-col  pb-4" + className} >

        {comments.map((comment, i) =>
     !showComment(i) ?null : 
            comment.message ?


                (
                    isReporter(comment.owner_id) ?
                        // Commentaire de reporter 
                        <div className={' px-0  text-base text-white items-center  flex text-sm flex-row  justify-end relative ' + (
                            (i == 0 || (comments[i - 1].owner_id != comment.owner_id || !comments[i - 1].comment_id)) ? " mt-4  " : " mr-8 "
                        )} key={"C" + comment.comment_id }>
                            {<span className=' mr-1  flew-grow text-left '   >{comment.message} </span>}
                            {
                                (i == 0 || (comments[i - 1].owner_id != comment.owner_id || !comments[i - 1].comment_id)) &&
                                <span onClick={() => setShowUser({ user_id: comment.owner_id, username: comment.username, quality: "Spectateur" })} className='flex flex-row  justify-center items-center mr-2 font-bold whitespace-nowrap ' >

                                    @{comment.username}
                                    <img src={project.urlImage("img_" + comment.owner_id)} className='  w-8 h-8 rounded-full m-1 sticky top-20' />
                                </span>

                            }

                        </div>
                        :
                        // Commentaire de spectateur 
                        <div className={' px-2  text-base text-white items-center  flex text-sm flex-row  justify-start relative ' + (
                            (i == 0 || (comments[i - 1].owner_id != comment.owner_id || !comments[i - 1].comment_id)) ? " mt-4  " : " ml-8 "
                        )} key={"C" + comment.comment_id}>

                            {
                                (i == 0 || (comments[i - 1].owner_id != comment.owner_id || !comments[i - 1].comment_id)) &&
                                <span onClick={() => setShowUser({ user_id: comment.owner_id, username: comment.username, quality: "Spectateur" })} className='flex flex-row  justify-center items-center mr-2 font-bold whitespace-nowrap ' >
                                    <img src={project.urlImage("img_" + comment.owner_id)} className='  w-8 h-8 rounded-full m-1 sticky top-20' />
                                    @{comment.username} :
                                </span>

                            }
                            {<span className=' ml-1  flex-wrap '   >{comment.message} </span>}
                        </div>
                )
                :
                // Score  de reporter 
                <div className=' w-full items-center  flex text-sm flex-col  justify-center ' key={"R" + comment.report_id} >
                    {<div className=' flex-grow flex text-sm flex-row items-center justify-center ' >
                        <span className=' mt-2  text-center    text-zinc-200 text-xs rounded  font-bold'   >{comment.created_at && (new Date(comment.created_at)).toLocaleTimeString()}</span>
                    </div>}

                    <div className={' px-0  text-base text-white items-center  flex text-sm flex-row  justify-end relative ' + (
                        (i == 0 || (comments[i - 1].owner_id != comment.owner_id || comments[i - 1].comment_id)) ? " mt-4  " : " mt-4 "
                    )} key={"R" + comment.report_id}>


                        {<div className=' ml-1 flex-grow flex text-sm flex-row items-center justify-center ' >
                            {"" + parseInt(comment.label) == "NaN" && <span className=' ml-1  text-center flex-wrap p-2  text-white bg-orange-400 rounded mx-0  font-bold'   >{comment.label}</span>}
                            {"" + parseInt(comment.label) != "NaN" && computedComments["status_step_" + comment.report_id] && <span className=' ml-1  text-center flex-wrap p-2  text-white bg-blue-400 rounded mx-0  font-bold'   >{computedComments["status_step_" + comment.report_id]}</span>}
                            {"" + parseInt(comment.label) != "NaN" && computedComments["status_comment_" + comment.report_id] && <span className=' ml-1  text-center flex-wrap p-2  bg-white text-zinc-900 rounded mx-1  font-bold'   >{computedComments["status_comment_" + comment.report_id]} </span>}
                        </div>}

                        {
                            (true || i == 0 || (comments[i - 1].owner_id != comment.owner_id || comments[i - 1].comment_id)) &&
                            <span onClick={() => setShowUser({ user_id: comment.owner_id, username: comment.username, quality: "Reporter" })} className='flex flex-row  justify-center items-center mr-2 font-bold whitespace-nowrap ' >
                                <img src={project.urlImage("img_" + comment.owner_id)} className='  w-8 h-8 rounded-full mx-1 sticky top-20' />

                            </span>

                        }
                    </div>
                </div>


        )
        }
        <div id="markerBottom"></div>
        {authUser && comments.length == 0 && <span className='flex py-5 text-white font-bold flex-row w-full justify-center items-center text-base  font-bold whitespace-nowrap' >
            Soit le premier à encourager ton équipe !

        </span>}

        {standAlone && !authUser &&
            <Link to="/signin"   ><div className={" underline mb-4 w-full text-white flex flex-col  items-center  justify-center p-8 "} > Connectes-toi pour laisser des commentaires et soutenir ton équipe !   </div></Link>

        }


    </div>
    //else 
    //return <span >{endpoint} </span>
}

export default Comments;
