


import { Link, useNavigate } from "react-router-dom";
import React,{ useEffect, useState } from 'react';
import { projectInit, setToken, getAuthUser } from './service/mftc-api';
import Footer from './components/Footer';
import ButtonApi from './components/ButtonApi';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as fa from '@fortawesome/free-solid-svg-icons'
import Webcam from 'react-webcam'

const videoConstraints = {
  width: 400,
  height: 400,
  facingMode: 'user', 
}


function Profile({  }) {

const[openCamera,setOpenCamera]=useState();
  const [picture, setPicture] = useState('')
const webcamRef = React.useRef(null)
const capture = React.useCallback(() => {
  const pictureSrc = webcamRef.current.getScreenshot()
  setPicture(pictureSrc)
})
  const navigate = useNavigate();

  const [project, setProject] = useState(null);
  const [hasAccount, setHasAccount] = useState(true);
const [authUser, setAuthUser] = useState(getAuthUser())
  const [activeEndpoint, setActiveEndpoint] = useState(null)
  const [paramsEndpoint, setParamsEndpoint] = useState(null)
  const [activeEndpointReady, setActiveEndpointReady] = useState(false)
  const [pendingCode, setPendingCode] = useState(false)
const [errorMessage,setErrorMessage]=useState();
const [email,setEmail]=useState();
const [urlImage,setUrlImage]=useState();
const [user,setUser]=useState();
const [mod,setMod]=useState();
  async function getProject() {
    console.log("getProject", process.env.REACT_APP_MFTC_SERVER_URL, process.env.REACT_APP_PROJECT_NAME)
    const p = await projectInit(process.env.REACT_APP_MFTC_SERVER_URL, process.env.REACT_APP_PROJECT_NAME)
    console.log('project', p);

    setProject(p);

  }

  useEffect(() => {
    getProject();
  }, [])

useEffect(()=>{
  
 if(project) getUser()
},[project])



useEffect(()=>{


  if( !urlImage)  setUrlImage(  "img_" +  user?.user_id  )

},[user,urlImage])


const getUser =async()=>{
setMod()
  var  result = await project.api("/get_profile/:user_id",{args:{user_id : authUser.user_id}} )
setUser(result); 

}

useEffect(()=>{

  if(!mod)
  {
   if(!urlImage || urlImage?.substring(0,4)== "img_")   setUrlImage( "img_" +  user?.user_id  )
    document.getElementById("username").value = user?.username
  }
},[mod,user])


  const save_profile = async () => {
   
    var username = document.getElementById('username').value;
    if( !username || username.length<3) {
      setErrorMessage(" Nom d'utilisateur trop court ")
      return
    }
          var result = await project.api("/update_profile", { body: {  urlImage,username ,user_id : authUser.user_id} })
       if (result.error)
       {
      
    setErrorMessage(result.error)
       }
       else {
        getUser()
       }
    
        }
      
    



  return (
    <>
<div className="fixed text-white font-bold p-0 px-0 w-full  flex justify-start items-center  bg-zinc-800 z-20">
  <a   href='/'><FontAwesomeIcon  icon={fa.faArrowLeft} className='text-white text-xl mr-1  px-9 py-8 ' /></a>
        <a href="/"   className='text-3xl   italic font-bold'  style={ {} }  >    <span>Live</span><span>my</span><span  className='text-red-700 ' >Game</span><span className='text-red-700 text-4xl mb-4' >!</span></a>

      </div>
      <div className=" pt-32 flex justify-start items-center flex-col  min-h-screen bg-zinc-800  w-screen text-white   ">

        <div className=" w-full  ">
     
     
            <div className="  flex flex-col  justify-start items-center">


            {
                 <span className='mb-1 text-xl'>PROFIL </span> }

<div  className= {  'text-white  justify-start items-center flex relative transition-all duration-500 '  + (openCamera ||picture!=""  ?  "  w-60 h-80 " : "  w-40 h-40 " ) } >
   <div  className= {  'text-white rounded-full bg-black  overflow-clip  justify-center items-center flex  '  + (openCamera||picture!=""  ?  "  w-60 h-60 " : "  w-40 h-40 ")}>
   {  openCamera  ? (
          <Webcam
            audio={false}
            height={400}
            ref={webcamRef}
            width={400}
            screenshotFormat="image/jpeg"
            videoConstraints={videoConstraints}
            mirrored={true}
          />
        ) : (
          <>
          
       { picture || urlImage ?  <img className=''    src={picture ||  project?.urlImage(urlImage)        } /> :
         <FontAwesomeIcon  icon={fa.faUser }    fontSize={"100px"} />
         
        }</>
        )}
        
        
     { !openCamera &&  !picture   &&   <FontAwesomeIcon icon={fa.faCamera } onClick={()=>{ setPicture("")    ;setOpenCamera(true)}} className="absolute bottom-2 right-2 text-4xl shadow"/>  }
     {   <div className="absolute bottom-2  left-0 text-base w-full flex flex-row justify-center">
      { picture!="" &&
          <>
          <div
            onClick={(e) => {
            
           
             setUrlImage(picture)
             console.log(picture);
             setPicture("")
             setMod(true)
            }}
            className=" p-2 bg-black text-white rounded mx-4"
          >
            Valider
          </div>
          <div
            onClick={(e) => {
              setOpenCamera(true);
              e.preventDefault()
              setPicture()

            }}
            className=" p-2 bg-black text-white rounded mx-4"
          >
            Reprendre
          </div></>
         }
     {  openCamera  &&    <><div
            onClick={(e) => {
              e.preventDefault()
              setOpenCamera(false);
              capture()
            }}
            className=" p-2 bg-black text-white rounded mx-4"
          >
            Prendre
          </div><div
            onClick={(e) => {
              e.preventDefault()
              setOpenCamera(false);
             
            }}
            className=" p-2 bg-black text-white rounded mx-4"
          >
            Annuler
          </div></>
}  
      </div>   }      </div>   </div>

                      {
            <div className='flex flex-col justify-start w-2/3 m-1'>
                  <span className='p-1'>Nom d'utilisateur</span>
                  <input id="username" className=' rounded  p-1 w-full bg-transparent border-b border-white   '  onChange={()=> setMod(true)} defaultValue={ ""} type="text" />
                </div>
              }
            

              <div className='flex flex-col justify-start w-2/3'>
                <span className='p-1'>Adresse email</span>
               <span>{ authUser.email   }</span>
              </div>


 
              {
                errorMessage && <div onClick={()=>setErrorMessage(null)} className=" bg-red-800 text-white w-2/3  flex flex-col justify-center  items-center p-2 my-2 rounded-lg">
                  <div className="w-full flex justify-end"><FontAwesomeIcon icon={fa.faClose }/></div>
                  <FontAwesomeIcon icon={fa.faWarning }/><span  >{errorMessage} </span></div>
              }
              
             

{  (mod) &&  <div className=" flex flex-row   justify-center  ">
<ButtonApi className="bg-white text-zinc-900 w-1/2 p-2 m-2" project={project} endpoint={`/set_match`}     title={"Enregistrer"} callback={save_profile} />
<ButtonApi className="bg-white text-red-900 w-1/2 p-2 m-2" project={project} endpoint={`/set_match`}     title={"Annuler"} callback={getUser} />
</div> 

}
              

            </div>
            
   

        </div>

      </div>
      <Footer />
    </>
  );
}

export default Profile;
