
import './App.css';


import { useEffect, useState, useMemo, useContext } from 'react';
import { projectInit, setToken, getAuthUser } from './service/mftc-api';
import Auth from './Auth';
import DataLabel from './components/DataLabel';
import ChooseNature from './components/ChooseNature';
import ButtonApi from './components/ButtonApi';
import FormApi from './components/FormApi';
import { useParams } from 'react-router';
import FormMap from './components/FormMap';
import FormNature from './components/FormNature';
import FormAddData from './components/FormAddData';
import FormRef from './components/FormRef';
import FormDataLabel from './components/FormDataLabel';
import FormDesign from './components/FormDesign';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as fa from "@fortawesome/free-solid-svg-icons";
import Header from './components/Header';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import 'dayjs/locale/fr';
import dayjs from 'dayjs';
import { MfctContext } from './context';
const OFFEST_LOCAL_TIMEDATE=(new Date()).getTimezoneOffset()*60000;
function Home() {


const  {authUser,project} = useContext(MfctContext);

 const urlparams = useParams();

const slug = urlparams.slug;


  const [path, setPath] = useState(urlparams.path ?? localStorage.getItem('path')) ;
  const [datas, setDatas] = useState(null);


  const [isOrderMode, setIsOrderMode] = useState(false)

const [ dataToOrder, setDatatoOrder] = useState(null);

const [isEditMode, setIsEditMode] = useState(false)
const [ dataToEdit, setDatatoEdit] = useState(null);
const [editDesign , setEditDesign] = useState(false);

const [isMoveMode, setIsMoveMode] = useState(false)
const [ dataToMove, setDatatoMove] = useState(null);

  const [isModificationMode, setIsModificationMode] = useState(false)
  const [activeEndpoint, setActiveEndpoint] = useState(null)
  const [chooseNature, setChooseNature] = useState(false);
  const [natures, setNatures] = useState(null);
  const [history, setHistory] = useState(JSON.parse( localStorage.getItem('history') ??"[]"));


const [editNature,setEditNature]= useState(false);

  const [natureSelect, setNatureSelect] = useState(null);
  const [isCollectionSelect, setIsCollectionSelect] = useState(null);
  const [isReferenceSelect, setIsReferenceSelect] = useState(null);


  const [chooseMap,setChooseMap]= useState(false);

  const [chooseRef, setChooseRef] = useState(null);
  const [chooseAddData, setChooseAddData] = useState(null);

  const [pathRef, setPathRef] = useState(null);

  const  [disabled,setDisabled]=useState(false)

const attributes = useMemo(()=>{

return datas?.filter(d=>d.concept_id )  ?? []

},[datas])
const isEditor = useMemo(()=>{

  return authUser && authUser.user_id ===1 
},[authUser])



  async function getDatas() {

   if(path){
    localStorage.setItem('path',path)
   } 
   else
   {
    localStorage.removeItem('path')
   }

    if (!project)
      return

    if (slug && !path)
      var result = await project.api("/get_datas_slug/:slug", { args: { slug } })
    else
      var result = await project.api("/get_datas/:path", { args: { path } })

    setDatas(result);

  }


async function setPathByDataId(data_id){
  var data = await project.api("/get_data/:data_id", { args:{ data_id }})
  if (data.path) setPath(data.path);

}


  async function moveData (data){

    if(!dataToMove)
    {
      setDatatoMove(data);
      return 
    }
    var result = await project.api("/move_data/:data_id/:parent_id", { args: {data_id: dataToMove.data_id,parent_id: data.data_id} })
    setDatatoMove(null);
    setIsMoveMode(false);
    await     getDatas();
    } 


async function orderData (data){

if(!dataToOrder)
{
  setDatatoOrder(data);
  return 
}
var result = await project.api("/order_data/:data_id/:new_path", { args: {data_id: dataToOrder.data_id,new_path: data.path} })
setDatatoOrder(null);
setIsOrderMode(false);
await     getDatas();
} 


  async function getNatures() {
    if (!project)
      return

    var result = await project.api("/get_natures")

    setNatures(result);

  }

  useEffect(() => {
    getNatures();

  }, [chooseNature, project]);



  async function replaceNature(infonature)
  {
    setNatures(  natures.map(n=> n.nature_id == infonature.nature_id ? infonature : n    )  )
  }

  const dataPath = useMemo(
    () => (slug || path) && datas ? datas.find(d => d.path === path || (!path && slug === d.slug)) : null,
    [datas, path, slug]
  );

  useEffect(() => {

    if (!path) {

      setHistory([])
    }
    else if (history.length == 0 || history[history.length - 1] != path) {
      history.push(path)
      setHistory(history)
    }
    localStorage.setItem('history', JSON.stringify(history))
    console.log("setHistory", history);
    setDatas(null);
    console.log("getDatas ")
    getDatas();


  }, [project, path])

  const goBack = () => {
    history.pop();

    if (history.length == 0) setPath(null);
    else {
      var newpath = history.pop();
      setPath(newpath);
    }
  }


  const create_slug = async (endpoint, args) => {


    if (!dataPath) return;
    var result = await project.api("/create_slug", { body: dataPath, endpoint: endpoint })
    console.log(result)
    await getDatas();
  }

  async function setLive (){
      var result = await project.api("/set_show", { body: {data_id: dataPath.data_id,is_live:!dataPath.is_live} })
    await getDatas()
      
      } 
      async function resetLive (){
        var result = await project.api("/show_reset", { body: {data_id: dataPath.data_id} })
      await getDatas()
        
        } 
async function setStartShow(value){

  console.log(value.toISOString())
var body={ show_id: dataPath.show.show_id, start_at : value.toISOString().substr(0,16) }
console.log( value.toISOString().substr(0,11) ,dataPath.show.end_at  )
if(  !dataPath.show.end_at ||  dataPath.show.end_at===""  || value.toISOString()  > dataPath.show.end_at  )body.end_at = value.toISOString().substr(0,11)+"23:59"
  var result = await project.api("/update_show", { body})
  await getDatas();
}
async function setEndShow(value){
  console.log(value.toISOString())
  var result = await project.api("/update_show", { body: { show_id: dataPath.show.show_id, end_at : value.toISOString().substr(0,16)  } })
  await getDatas();
}

const valideGeoHash = async (geohash,lat,lng) => {

  setChooseNature(false);
  var result = await project.api("/set_geohash", { body: { path: dataPath.path, geohash,lat,lng    } })
  console.log(result)
  await getDatas();

}

const UseDesign = async (design_id) => {
  var result = await project.api("/update_data", { body: { data_id: dataPath.data_id, design_id } })
    console.log(result)
    await getDatas();

}

  const changeNature = async (nature, is_collection, is_reference) => {

    setChooseNature(false);
    var result = await project.api("/update_data", { body: { data_id: dataPath.data_id, nature_id: nature ? nature.nature_id : null, is_collection, is_reference } })
    console.log(result)
    await getDatas();

  }


  const parentPath = (path) => {
    var parts = path.split('-');
    parts.pop();
    return parts.join('-');
  }

const add_concept_data = () => {

  setNatureSelect(null);
  setChooseAddData({  concept_id : nature.nature_id }); 

}
  const add_ref = async (endpoint, args) => {
   
    setChooseRef(dataPath);

  }
  const add_ref_validate = async (data) => {
   
    setChooseRef(null);
    var result = await project.api("/add_data", { body: data })
  
    await getDatas();
  }

const nature = useMemo(()=>{

if(!dataPath || !dataPath.nature_id || !natures) return null

var n =  natures.find(n0 => n0.nature_id == dataPath.nature_id)

return n;
  },
  [dataPath,natures])


  const getNatureLabel = (data) => {

    if (natures) {
      var n = natures.find(n0 => n0.nature_id == data.nature_id)
      if (n) return n.label
    }
    return "Data";
  }


  const getNatureColor = (data) => {

    if (natures) {
      var n = natures.find(n0 => n0.nature_id == data.nature_id)
      if (n) return n.color ?? "#000"
    }
    return "#000";


  }






  const delete_data = async (data) => {

    var result = await project.api("/delete_data/:path", { args: { path: data.path } })
    await getDatas();
  }






  return (
    <>
    {chooseMap && <FormMap close={()=>setChooseMap(false)}   valideGeoHash={authUser && dataPath?.owner_id===authUser?.user_id && valideGeoHash }   geohash={dataPath?.show.geohash}   />}
      {activeEndpoint &&
        <FormApi activeEndpoint={activeEndpoint} setActiveEndpoint={setActiveEndpoint}/>
      }
      <div className="w-screen min-h-screen  flex justify-start items-center flex-col bg-white ">
<Header     enabledMenu={(value)=> setDisabled(value) }/>
        <div disabled={disabled} className="     w-full mt-24">
        
        


          <div className="border-t border-slate-300  w-full">
         
          </div>

          <div className=" flex overflow-x-auto text-xs items-start justify-start">
            {
              datas && <>
                {
                  datas.filter(d => dataPath && d.data_id !== dataPath.data_id && dataPath.path && !dataPath.path.indexOf(d.path)).reverse().map(data =>

                    <>  <span className="whitespace-nowrap " onClick={() => setPath(data.path)} key={data.data_id}> <DataLabel data={data} natures={natures} nonature={true} suffix="/" />  </span>   </>
                  )
                }</>}
            {dataPath && <DataLabel data={{ label: "  Home", natureLabel: "HOME", natureColor: "#444456" }} onClick={() => setPath(null)} suffix="/" natures={natures} nonature={true} />
            }
          </div>
          <span className="whitespace-nowrap flex flex-row w-full" > {

            history.length > 0 && <div className="" onClick={goBack}  ><DataLabel data={{ label: "Back", natureLabel: "<", natureColor: "#000" }} natures={natures} /> </div>
          }
            <span className="whitespace-nowrap  w-full">   <DataLabel data={dataPath ?? { label: "Home", natureLabel: "HOME", natureColor: "#444456" }} natures={natures} onClickRef={(data_id)=>{ setPathByDataId(data_id) }} /> </span> </span>

          {isEditor && (  !dataPath || dataPath.data_id) && <div className="border-t border-slate-300 p-2 w-full ">
            {false && dataPath && <ButtonApi endpoint={`/update_data`} title={" Set Nature "} callback={() => { setChooseNature(true) }} setActiveEndpoint={setActiveEndpoint} />}
       
           
            { 
            datas && datas.filter(d =>    dataPath &&  d.level==dataPath.level+1 && d.is_collection &&   dataPath.path.indexOf(d.path)).map(d => 
             <ButtonApi endpoint={`/add_data`} className="text-white " style={{ background: getNatureColor(d) }} title={` + ${ getNatureLabel(d) }  ${ d.is_reference ? "*":""  }`} callback={() => { setNatureSelect( natures.find(n => n.nature_id === d.nature_id)); d.is_reference ? setChooseRef(d) : setChooseAddData(d) }}  setActiveEndpoint={setActiveEndpoint} key={d.data_id}/>            
            )}

            {(!dataPath || (!dataPath.is_collection || !dataPath.is_reference)) && <ButtonApi endpoint={`/add_data`} className="text-white " style={{ background: dataPath && dataPath.is_collection ? getNatureColor(dataPath) : "#000" }} title={` Add ${dataPath && dataPath.is_collection && dataPath.nature_id ? getNatureLabel(dataPath) : "Data"}  `} callback={() => { setNatureSelect(dataPath && dataPath.is_collection ? natures.find(n => n.nature_id === dataPath.nature_id) : null); setChooseAddData(dataPath??{}) }} setActiveEndpoint={setActiveEndpoint} />}
            {dataPath && (!dataPath.is_collection || dataPath.is_reference) && <ButtonApi endpoint={`/add_data`} className="text-white " style={{ background: dataPath && dataPath.is_collection ? getNatureColor(dataPath) : "#000" }} title={` Add ${dataPath && dataPath.is_collection && dataPath.nature_id ? getNatureLabel(dataPath) : "Data"}  *`} callback={add_ref}  setActiveEndpoint={setActiveEndpoint} />}

            {dataPath?.nature_id  && <ButtonApi endpoint={`/add_data`} className="text-white " style={{ background:getNatureColor(dataPath)  }} title={` Add Concept Data`} callback={add_concept_data} setActiveEndpoint={setActiveEndpoint} />}

            {authUser &&  dataPath && <ButtonApi endpoint={`/data_sync/:data_id`} className="text-white bg-green-500" title={` Sync  `} params={ {args:{data_id:dataPath.data_id }}} withoutConfirm={true} />}
            {authUser &&  dataPath && dataPath.data_id && !dataPath.slug  && <ButtonApi endpoint={`/create_slug`} className="text-white " style={{ background: dataPath && dataPath.is_collection ? getNatureColor(dataPath) : "#000" }} title={` Create Slug`} callback={create_slug} setActiveEndpoint={setActiveEndpoint} />}

            { authUser && !dataPath?.is_collection &&  nature?.default_design_id && <button className={ 'mx-1 p-1 px-2 text-xs rounded-full font-bold text-white bg-black border-4 ' +(!dataPath.is_live ? ' border-red-600 ':'border-green-700')  }onClick={() => setLive()}>{"Live " + ( dataPath.is_live?" ! ":"")} </button>}
     
            { authUser && !dataPath?.is_collection &&  nature?.default_design_id && dataPath.is_live  && <button className={ 'mx-1 p-1 px-2 text-xs rounded-full font-bold text-white bg-black border-4 '  }onClick={() => resetLive()}>{"Reset Live !"} </button>}
                   {authUser && <button className='mx-1 p-1  text-xs rounded font-bold text-white bg-gray-500' onClick={() => setIsEditMode(!isEditMode)}>{"Edit "} </button>}
            {authUser && <button className='mx-1 p-1  text-xs rounded font-bold text-white bg-blue-500' onClick={() => setIsOrderMode(!isOrderMode)}>{"o Order "} </button>} 
            {authUser && <button className='mx-1 p-1  text-xs rounded font-bold text-white bg-orange-500' onClick={() => setIsMoveMode(!isMoveMode)}>{"<> Move "} </button>}
            {authUser && <button className='mx-1 p-1  text-xs rounded font-bold text-white bg-red-500' onClick={() => setIsModificationMode(!isModificationMode)}>{"x Remove "} </button>}


            {authUser &&  dataPath && dataPath.slug && <a className=' p-2 rounded' href={"/data/" + dataPath.slug} target="_blank"> Link </a>}

          </div>}
{   
dataPath?.show && isEditor && 
<div className='p-2 flex flex-col w-full justify-between'>
<LocalizationProvider dateAdapter={AdapterDayjs}  adapterLocale="fr" >
      <div className=' m-1 w-full'>
      
        <DateTimePicker className=' p-2 m-1 w-full' label="Start" defaultValue={dataPath.show.start_at ?dayjs(dataPath.show.start_at):null } onAccept={(newValue) => setStartShow(newValue)} />
    
      </div>
      <div className=' m-1 w-full'>
    
        <DateTimePicker  className=' p-2 m-1 w-full'  label="End"   defaultValue={dataPath.show.end_at ?dayjs(dataPath.show.end_at):null } onAccept={(newValue) => setEndShow(newValue)}/>
    
      </div>
    </LocalizationProvider>
   <div    onClick={() => setChooseMap(true)} className=' bg-black rounded-lg text-white my-2 font-bold  p-2 flex flex-row  items-center' >
    {
dataPath.show.geocontent.city

    }  {dataPath?.data_id && (dataPath.show  || (authUser && dataPath?.owner_id===authUser?.user_id)) && <FontAwesomeIcon icon={ fa.faEarth   }  className=' p-2  text-xl' /> }</div></div>
}


          {chooseNature && <>
            <div className="p-2 border-t border-slate-300  "> AFFECTATION DE NATURE    <button className="p-1 rounded-full " onClick={() => { setChooseNature(false); setIsCollectionSelect(null); setIsReferenceSelect(null); }} >x</button></div>


            <ChooseNature setNature={changeNature} natures={natures} project={project} updateNatures={getNatures} />

          </>
          }

          {chooseAddData &&
     <FormAddData closeCallback={()=> setChooseAddData(null)}   datas={datas} project={project} parent={chooseAddData?.data_id ? chooseAddData :null   } defaultData={ {

      nature_id :chooseAddData.is_collection ? chooseAddData?.nature_id :null,concept_id : chooseAddData?.concept_id 
     }  }  getNatures={getNatures} natures={natures} setPathData={setPath}   />

          }


          {chooseRef &&
        
          <FormRef closeCallback={()=>setChooseRef(null)}  path={chooseRef.path } defaultData={{ nature_id:chooseRef.is_collection ?  chooseRef.nature_id:null,   parent:chooseRef }} getNatures={getNatures} natures={natures} project={project}  valideRef={ add_ref_validate }    /> 
          
        

          }

          {!chooseRef && !chooseAddData && datas && <>
            {
              datas.filter(d => !d.concept_id &&((!path && !slug) || (   dataPath &&  (d.level==dataPath.level+1 || (!d.is_collection && !dataPath.is_collection )) &&   dataPath.path.indexOf(d.path)) )   ).map(data =>

                <div className="border-t border-slate-300 px-1  w-full flex flex-row" key={data.data_id}>
                  <span className=' w-full p-0' > <DataLabel data={data} natures={natures} onClick={() => setPath(data.path)}  onClickRef={(data_id)=>{ setPathByDataId(data_id) }} />
                  
                  {isEditMode &&  data === dataToEdit && !dataToEdit.reference_id && <div><FormDataLabel  data={data} natures={natures} closeCallback={()=>{setDatatoEdit(null)}}  getDatas={getDatas} /></div> } 
                  {isEditMode &&  data === dataToEdit &&  dataToEdit.reference_id && <div><FormRef  defaultData={data}  getNatures={getNatures} path={data.path}   natures={natures} closeCallback={()=>{setDatatoEdit(null)}}  getDatas={getDatas} /></div> }
                  </span>
                  {isMoveMode && !dataToMove && <ButtonApi className=" font-bold text-white bg-orange-500 w-6 h-6 p-0 rounded-full "   endpoint={`/move_data/:data_id/:parent_id`} title={"<>"} callback={() => { moveData(data) }}  setActiveEndpoint={setActiveEndpoint}/>}
                  {isEditMode  && <button className=" font-bold text-white bg-gray-500 p-0 rounded  text-xs p-1 h-6 m-2"  onClick={() => { setDatatoEdit(data) }} >Edit</button>}
                             {isMoveMode && dataToMove && ((dataToMove.nature_id==data.nature_id && data.is_collection) || (dataToMove.nature_id!==data.nature_id && !data.is_collection) ) &&  dataToMove.data_id!==data.data_id && <ButtonApi className=" font-bold text-white bg-orange-500 w-6 h-6 p-0 rounded-full " endpoint={`/move_data/:data_id/:parent_id`} title={"<>"} callback={() => { moveData(data) }} setActiveEndpoint={setActiveEndpoint} />}
                {isOrderMode && data.parent_id===dataPath?.data_id && <ButtonApi  className=" font-bold text-white bg-blue-500 w-6 h-6 p-0 rounded-full "  endpoint={`/order_data/:data_id/:new_path`} title={"o"} callback={() => { orderData(data) }}  setActiveEndpoint={setActiveEndpoint} />}
                  {isModificationMode && <ButtonApi className=" font-bold text-white bg-red-500 w-6 h-6 p-0 rounded-full " endpoint={`/delete_data/:path`} title={"x"} callback={() => { delete_data(data) }} setActiveEndpoint={setActiveEndpoint} />}
             
                </div>

              )
            }
            {
              attributes.length>0 && 
              <>
<h1 className=' w-full p-4 pb-1 font-bold text-base'>   Attributs </h1>
              {

         attributes.map(data =>

          <div className="border-t border-slate-300 px-1  w-full flex flex-row" key={data.data_id}>
            <span className=' w-full p-0' > <DataLabel data={data} natures={natures} onClick={() => setPath(data.path)}  onClickRef={(data_id)=>{ setPathByDataId(data_id) }} />
            
            {isEditMode &&  data === dataToEdit && !dataToEdit.reference_id && <div><FormDataLabel  data={data}  natures={natures} closeCallback={()=>{setDatatoEdit(null)}}  getDatas={getDatas} /></div> } 
            {isEditMode &&  data === dataToEdit &&  dataToEdit.reference_id && <div><FormRef  defaultData={data}  getNatures={getNatures} path={data.path} natures={natures} closeCallback={()=>{setDatatoEdit(null)}}  getDatas={getDatas} /></div> }
            </span>
            {isMoveMode && !dataToMove && <ButtonApi className=" font-bold text-white bg-orange-500 w-6 h-6 p-0 rounded-full "   endpoint={`/move_data/:data_id/:parent_id`} title={"<>"} callback={() => { moveData(data) }}  setActiveEndpoint={setActiveEndpoint}/>}
            {isEditMode  && <button className=" font-bold text-white bg-gray-500 p-0 rounded  text-xs p-1 h-6 m-2"  onClick={() => { setDatatoEdit(data) }} >Edit</button>}
                       {isMoveMode && dataToMove && ((dataToMove.nature_id==data.nature_id && data.is_collection) || (dataToMove.nature_id!==data.nature_id && !data.is_collection) ) &&  dataToMove.data_id!==data.data_id && <ButtonApi className=" font-bold text-white bg-orange-500 w-6 h-6 p-0 rounded-full " endpoint={`/move_data/:data_id/:parent_id`} title={"<>"} callback={() => { moveData(data) }} setActiveEndpoint={setActiveEndpoint} />}
          {isOrderMode && data.parent_id===dataPath?.data_id && <ButtonApi  className=" font-bold text-white bg-blue-500 w-6 h-6 p-0 rounded-full "  endpoint={`/order_data/:data_id/:new_path`} title={"o"} callback={() => { orderData(data) }}  setActiveEndpoint={setActiveEndpoint} />}
            {isModificationMode && <ButtonApi className=" font-bold text-white bg-red-500 w-6 h-6 p-0 rounded-full " endpoint={`/delete_data/:path`} title={"x"} callback={() => { delete_data(data) }}  setActiveEndpoint={setActiveEndpoint} />}
       
          </div>

        )


              }</>
            }
            
            
            
            
            
            </>}


{isEditor && authUser &&  dataPath?.nature_id &&          <div className="p-2 border-t border-slate-300  font-bold   flex flex-row  items-center justify-between "> NATURE  
<FontAwesomeIcon  className='mr-4  text-xl text-black'  onClick={() => setEditNature(!editNature)} icon={ editNature ?  fa.faCaretUp: fa.faCaretDown  }  /></div>
}
  {editNature && natures && dataPath && <>
   


            <FormNature  nature={natures.find(d=>dataPath &&  dataPath.nature_id ==d.nature_id ) } natures={natures} data= {dataPath} project={project} updateNatures={getNatures} getNatures={getNatures}  replaceNature={replaceNature} />

          </>
          }
           {isEditor &&  authUser &&  dataPath?.nature_id &&    <div className="p-2 border-t border-slate-300  font-bold   flex flex-row  items-center justify-between "> DESIGN  
         <FontAwesomeIcon  className='mr-4  text-xl text-black' icon={ editDesign ?  fa.faCaretUp: fa.faCaretDown  }  onClick={() => setEditDesign(!editDesign)} />
           </div>}
      
  {editDesign && natures && dataPath?.nature_id && <>
           


            <FormDesign  nature={natures.find(d=>dataPath &&  dataPath.nature_id ==d.nature_id ) }  UseDesign={UseDesign} natures={natures} data= {dataPath} project={project} updateNatures={getNatures} replaceNature={replaceNature} />

          </>
          }

        </div>






      </div>

    </>
  );
}

export default Home;
