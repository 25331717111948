import { useContext, useEffect, useState } from "react";
import { MfctContext } from "../context";




function    FormApi ({ activeEndpoint,setActiveEndpoint })   {

const {project,getProject} = useContext(MfctContext)
 
  const [paramsEndpoint, setParamsEndpoint] = useState(null)
  const [activeEndpointReady, setActiveEndpointReady] = useState(false)


useEffect(() => {
  refreshActiveEndpoint();

},[])



  const confirmActiveEndpoint = async () => {
    var args = argsToFill();
    var nbFilled = 0;
    var params = { args: {} }
    args.forEach((arg) => {
      var value = document.getElementById("input_" + arg).value;
      params.args[arg] = value;
      if (value) nbFilled += 1;
    })

    if (nbFilled == args.length) {
      await project.api(activeEndpoint, params)
      await getProject();
    }
    else {
      alert("Please fill the informations " + nbFilled + " " + args.join(", "))
    }
    setActiveEndpoint(null);
    setActiveEndpointReady(false)
  }

  const argsToFill = () => {
    if (!activeEndpoint) return [];
    var parts = activeEndpoint.split("/");
    var argsToFill = [];
    parts.forEach(element => {
      if (element.startsWith(":")) {
        var arg = element.substring(1);
        //  if (!args[arg])
        argsToFill.push(element.substring(1))
      }
    });
    return argsToFill;
  }

  const refreshActiveEndpoint = () => {
    var args = argsToFill();
    var nbFilled = 0;

    args.forEach((arg) => {
      var value = document.getElementById("input_" + arg).value;

      if (value) nbFilled += 1;
    })
    setActiveEndpointReady(nbFilled == args.length)
  }




return   <div className='w-screen h-screen fixed bg-white flex justify-center items-center '
>
  <div className='flex flex-col '>
    <h1>{activeEndpoint}</h1>{
      argsToFill().map((arg) =>
        <div key={arg} className='m-1 p-1 flex flex-col'>
          <span>{arg}</span>
          {
            project.endpoints[activeEndpoint]["enum_" + arg] ?
              <select id={"input_" + arg} placeholder='Select '>
                <option value={null}>Selectionner</option>{
                  project.endpoints[activeEndpoint]["enum_" + arg].map(value =>
                    <option key={value} value={value}>{value}</option>)
                } </select>
              : <>
                {project.endpoints[activeEndpoint]["list_" + arg] === "entities" ?
                  <select id={"input_" + arg} placeholder='Select '>
                    <option value={null}>Selectionner</option>{
                      Object.keys(project.content.entities).map(value =>
                        <option key={value} value={value}>{value}</option>)
                    } </select>
                  : <input id={"input_" + arg} className='border border-black' onChange={refreshActiveEndpoint} />
                } </>}
        </div>
      )
    }
    <div className='flex flex-row w-full'>
      <button className='p-2 m-1 text-white bg-red-500' onClick={() => { setActiveEndpoint(null) }} >Annuler</button>
      <button className={'p-2 m-1 text-white ' + (activeEndpointReady ? "bg-green-500" : "bg-gray-500")} onClick={activeEndpointReady ? confirmActiveEndpoint : null} > Valider</button>
    </div>
  </div>
</div>
} 

export default FormApi;
