

import * as fa from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  useMemo, useState } from 'react';
import { projectInit, setToken, getAuthUser } from '../service/mftc-api';
import ButtonApi from './ButtonApi';




function    UserProfile ({ user,close,project })  {

  return   <>
     
       <div className={' flex flex-col items-center justify-start fixed top-0 left-0 z-30 bg-black w-screen h-screen transition-all  duration-500  bg-opacity-50 '  }   onClick={close}   >
       

       <div className="  flex flex-col  justify-start items-center text-white   p-6  box-border  bg-zinc-900 rounded shadow   w-11/12 m-20 ">



     <span className='mb-1 text-xl font-bold'>{ user.quality ??   "Reporter" } </span>

<div  className= {  'text-white my-4 justify-start items-center flex relative transition-all duration-500  w-60 h-60 ' } >
<div  className= {  'text-white rounded-full bg-black  overflow-clip  justify-center items-center flex   w-60 h-60 '}>

<>

{ true?  <img className=''    src={ project.urlImage( "img_" + user.user_id )        } /> :
<FontAwesomeIcon  icon={fa.faUser }    fontSize={"100px"} />
}</>

      </div>   </div>

          
<span className='flex flex-col justify-center font-bold m-1 p-4'>@{ user.username}
    </span>
  
  
 


  

</div>
     
          </div>
      </>

  //else 
  //return <span >{endpoint} </span>
} 

export default UserProfile;
