

import {  useContext, useMemo } from 'react';
import { MfctContext } from '../context';




function    ButtonApi ({ endpoint, title, callback, withoutConfirm,className,style,setActiveEndpoint,params })  {


const { project,getProject} = useContext(MfctContext);

  const ClickApi = async (endpoint) => {

    setActiveEndpoint(endpoint,params);
   
  
  }
  const ClickApiNow = async (endpoint) => {
  
    await project.api(endpoint,params)
    if (!project.endpoints[endpoint].norefresh)
     await getProject();
  
  }

  
  if (!callback) {
    callback = withoutConfirm ? () => ClickApiNow(endpoint) : () => ClickApi(endpoint);
  }

  if (project && project.endpoints[endpoint])
    return <button className={ " my-3 p-1 rounded-md mx-2 font-bold text-xs " +className } style={style}  onClick={callback}   > {title} </button>
  //else 
  //return <span >{endpoint} </span>
} 

export default ButtonApi;
