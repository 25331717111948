
import { useContext, useEffect, useMemo, useState } from 'react';
import { AutoTextSize } from 'auto-text-size';
import DataLabel from './DataLabel';
import { compute_data } from '../tools/live';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as fa from '@fortawesome/free-solid-svg-icons'
import { Link, useNavigate, useParams,useLocation } from 'react-router-dom';
import Geohash from 'latlon-geohash';
import { isMobile } from 'react-device-detect';
import SwitchButton from './SwitchButton';
import { socket } from '../service/socket';
import Comments from './Comments';
import FormMap from './FormMap';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import Loader from './Loader';
import { MfctContext } from '../context';
import { subscribeUser } from '../subscription';

function DataLive({ show, setShowUser, updateData, newUpdateData, reportStart, reportStop, currentGeoHash, className, cover, owner_id, deleteLive, resetLive, updateShow, updateReport }) {
  const navigate = useNavigate();
  const location =useLocation();
  const { project,authUser} = useContext (MfctContext );
  const { slug } = useParams();
  const [dataToUpdate, setDataToUpdate] = useState();
  const [labelToUpdate, setLabelToUpdate] = useState();
  const [computedTexts, setComputedTexts] = useState({});
  const [updatableDatas, setUpdatableDatas] = useState({})
  const [updatableLabels, setUpdatableLabels] = useState({})
  const [comments, setComments] = useState([]);
  const [computedComments, setComputedComments] = useState({})
const [chooseMap, setChooseMap]=useState(false);
const [chooseTime, setChooseTime]=useState(false);
const  [showLoader, setShowLoader] = useState(false);
const  [subscribe, setSubscribe] = useState( localStorage.getItem('subscribe_'+slug)   );
const [showSuggestions,setShowSuggestions]=useState(false);
  const getComments = async () => {

    var result = await project.api("/get_comments", { body: { show_id: show.show_id } })
    setComments(result);

  }

  const newComment = async () => {

    var text = document.getElementById("new_comment").value;

    if (text) {

      document.getElementById("new_comment").value = "";
      var result = await project.api("/add_comment", { body: { owner_id: authUser.user_id, username: authUser.username, message: text, show_id: show.show_id } })

    }
  }

  const updateComment = (new_comment) => {
    setComments([...comments.map(c => c), new_comment]);
    if (new_comment.owner_id == authUser.user_id) {

      //document.getElementById("markerBottom")?.scrollIntoView({ behavior: "smooth" });
    }
  }



  useEffect(() => {
    if (comments.length == 0) return;
    console.log("setUpdateCommentsByReports", show.reports, comments.length)
    var nb = 0;
    for (var i = 0; i < show.reports.length; i++) {


      for (var j = 0; j < show.reports[i].content.datas.length; j++) {
        var d = show.reports[i].content.datas[j];
        if (!comments.find(c => c.report_id >= d.report_id && c.data_id === d.data_id)) {
          comments.push(d);
          console.log("AddReport", show.reports[i].report_id);
          nb++;
        }
      }
    }
    if (nb > 0) setComments([...comments])
  }, [show])




  useEffect(() => {

    if (slug && show.slug && authUser) {
      getComments()

    }
    else setComments([])


  }, [slug, show, authUser]);


  useEffect(() => {
    console.log("setComputedComments", comments.length, show.content, show.content.design.content.design)

    const __CONSTANTS_DESIGNS = ["status_comment", "status_step"]
    for (var j = 0; j < __CONSTANTS_DESIGNS.length; j++) {
      const keystatus = __CONSTANTS_DESIGNS[j]
      const status_label = show.content.design.content.design[keystatus]
      //if (!show.content.datas) return
      if (!status_label) continue;
      var _datas = {};

      for (var i = 0; i < show.content.datas.length; i++)  _datas[datas[i].data_id] = { ...show.content.datas[i], label: "-" }

      for (var i = 0; i < comments.length; i++) {
        var report = comments[i];
        if (report.comment_id || !report.data_id) continue;

        //console.log("report_id TEST ", report)
        _datas[report.data_id] = { ..._datas[report.data_id], label: report.label }
        // if (computedComments[keystatus + "_" + report.report_id]) {   continue;}
        console.log("******* Report " + keystatus, report.report_id, report.data_id, report.label, _datas)
        const { text, data_, label_, completed } = compute_data(show, status_label.dynamic_label, Object.values(_datas))
        console.log(text, report);
        if (completed) computedComments[keystatus + "_" + report.report_id] = text
      }

    }
    console.log("computedComments ---", computedComments);
    setComputedComments({ ...computedComments });

  }, [comments, show])


const suggestions = useMemo(()=>{

if(! showSuggestions) return
var result =  [ "Bon match ! ", " Allez ! ","Que le meilleur gagne !"]
if(show.content.datas)
{
  result = [ ...show.content.datas.filter(da=>(!da.is_live_mode && da.label!="-" && da.nature_id!=39)).map(d=>d.label)  ,...result  ]
}


return result

},[showSuggestions,show])



  useEffect(() => {

    if (slug && show.slug && authUser) socket.on('updateComment/' + show.show_id, updateComment);

    return () => {
      if (slug && show.slug && authUser) socket.off('updateComment/' + show.show_id, updateComment);

    };

  }, [slug, show, authUser, comments]);


const subscribe_live= async ()=>{
  if(!slug) return;

  var tokenpush = localStorage.getItem('tokenpush');
  if(!tokenpush){
    
    subscribeUser( subscribe_live);
    return;
  }
setSubscribe("1");
localStorage.setItem('subscribe_'+slug, "1");
  if( tokenpush) await project.api("/subscribe_live",{body: {slug,tokenpush: JSON.parse( tokenpush)}})
 
} 
const unsubscribe_live= async ()=>{
 
  if(!slug) return;
  setSubscribe();
localStorage.removeItem('subscribe_'+slug);
  var tokenpush = localStorage.getItem('tokenpush');

  if( tokenpush) await project.api("/unsubscribe_live",{body: {slug,tokenpush: JSON.parse( tokenpush)}})
 
} 

  const subscribeSwitch = async(value)=>{
 if(subscribe==="1") 
 await unsubscribe_live();
else  
await subscribe_live();
}
  

  const standAlone = useMemo(() => {

    return slug && show.slug;

  }, [slug, show])


  function deg2rad(deg) {
    return (deg * Math.PI) / 180
  }


  function getDistanceFromLatLonInKm(geohash1, geohash2) {

    var { lat, lon } = Geohash.decode(geohash1)
    const lat1 = lat;
    const lng1 = lon;


    var { lat, lon } = Geohash.decode(geohash2)
    const lat2 = lat;
    const lng2 = lon;
    var R = 6371; // Radius of the earth in km
    var dLat = deg2rad(lat2 - lat1);  // deg2rad below
    var dLon = deg2rad(lng2 - lng1);
    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2)
      ;
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c; // Distance in km
    return Math.round(1000 * d);
  }

  const inCell = (col, row) => {

    var cells = Object.values(content.design)
    for (var i = 0; i < cells.length; i++) {
      var cell = cells[i];
      if (col >= cell.c0 && col <= cell.c1 && row >= cell.r0 && row <= cell.r1)
        return cell;
    }
    return null;
  }
  const diffusingReport = useMemo(() => {

    return authUser && show.reports && show.reports.find(r => r.owner_id == authUser.user_id)

  }, [authUser, show])




  const viewReport = (report) => {

    if (authUser)
      updateReport({ report_id: report.report_id, switchView: authUser.user_id })

    else
      navigate("/signin")

  }

  const isViewReport = (report) => {
    return authUser && ((report.views ?? {})[authUser.user_id])
  }

  const isUnWantedReport = (report) => {
    return authUser && ((report.views ?? {})[authUser.user_id] === false)
  }
  const isNoChoiceReport = (report) => {
    return authUser && (!(report.views ?? {})[authUser.user_id] && (report.views ?? {})[authUser.user_id] !== false)
  }
  const viewReportCount = (report) => {
    var l = Object.values(report.views ?? {})

    if (l.length == 0) return "";

    return l.filter(d => d).length + " / " + l.length
  }




  const likeShow = () => {

    if (authUser)
      updateShow({ show_id: show.show_id, switchLike: authUser.user_id })

    else
      navigate("/signin")

  }

  const isLike = useMemo(() => {
    return authUser && ((show.likes ?? {})[authUser.user_id])
  }, [authUser, show])

  const likeCount = useMemo(() => {
    return Object.values(show.likes ?? {}).length
  }, [show])

  const canDiffuse = useMemo(() => {

    var now = (new Date()).toISOString().substring(0, 16)
    if (now > show.end_at || now < show.start_at) return false
    return authUser && (authUser.user_id === show.owner_id || (show.geohash && currentGeoHash && getDistanceFromLatLonInKm(currentGeoHash, show.geohash) < 100)) && show.reports && (show.reports.findIndex(r => r.owner_id == authUser.user_id) === -1)

  }, [authUser, show])



  const live = useMemo(() => {
    return show
  }, [show])



  const isReporter = (user_id) => {


    return show?.reports.find(r => r.owner_id === user_id);

  }
  const status = useMemo(() => {

    var st = "-"
    var now = (new Date()).toISOString().substring(0, 16)
    if (now > show.end_at) return "FINI"
    if (now > show.start_at) return "EN DIRECT"

    var tmp = Math.round(((new Date(show.start_at)).getTime() - (new Date()).getTime()) / 60000);
    console.log(tmp, (new Date(show.start_at)), (new Date()))
    if (tmp < 0) return "DÉBUT..."
    if (tmp < 60) return "DANS  " + tmp + "mn"
    tmp = Math.round(tmp / 60);
    if (tmp < 24) return "H-" + tmp
    tmp = Math.round(tmp / 24);
    return "J-" + tmp



  }, [show])

  const nature = useMemo(() => {
    return show.content.nature
  }, [show])

  const design = useMemo(() => {
    return show.content.design
  }, [show])
  const content = useMemo(() => {
    return show.content.design.content
  }, [show])

  const attributes = useMemo(() => {
    console.log("ATTRIBUTES *************", show.content.attributes);
    return show.content.attributes
  }, [show])

const isOwner = useMemo(()=>{
return authUser && authUser.user_id ==show.owner_id

},[show,authUser])
  const datas = useMemo(() => {
    var datas_ = show.content.datas;
    //reports
    if (show.reports)
      show.reports.forEach(r => {
        if (r) {
          r.content.datas.forEach(d => {
            var id_ = datas_.findIndex(d0 => d0.data_id == d.data_id)
            if (id_ === -1) {
              datas_.push(d)
              // ça ne doit jamais passer là !!!!
            }
            else if (!datas_[id_].updated_at || datas_[id_].updated_at < d.updated_at) {
              datas_[id_] = { ...datas_[id_], ...d }

            }
          })

        }

      })

    console.log("refresh datas")
    return datas_.map(d => dataToUpdate?.data_id === d.data_id ? dataToUpdate : (labelToUpdate?.data_id === d.data_id ? labelToUpdate : d))
  }, [show, dataToUpdate, labelToUpdate])





  useEffect(() => {

    var texts = {};
    var updatableDatas_ = {}
    var updatableLabels_ = {}
    var keys = Object.keys(content.design);

    for (var i = 0; i < keys.length; i++) {
      const { text, data_, label_, otherdata_ } = compute_data(show, content.design[keys[i]].dynamic_label, datas)
      if (content.design[keys[i]].dynamic_label.length == 1 && content.design[keys[i]].dynamic_label[0].relativePath == ".IMAGE") {
        texts[keys[i]] = "IMG:" + text;

        continue;
      }

      texts[keys[i]] = text;
      if (data_ && (diffusingReport || !owner_id)) updatableDatas_[keys[i]] = data_;
      if (data_ && !diffusingReport && owner_id) updatableLabels_[keys[i]] = data_;
      if (label_ && !diffusingReport && owner_id) updatableLabels_[keys[i]] = label_;
      if (show.content.attributes && label_ && show.content.attributes[label_.nature_id] && (diffusingReport || !owner_id)) updatableDatas_[keys[i]] = label_;
    }



    setComputedTexts(texts);
    setUpdatableDatas(updatableDatas_)
    setUpdatableLabels(updatableLabels_)
  }, [datas, owner_id])


  const shareLive = async () => {
    if (navigator.share) {
      try {
        await navigator
          .share(
            { url: "https://live-my-game.com/live/" + show.slug, title: show.label, text: "Vivez le Game en direct" }
          )
          .then(() => 1
            //alert("Hooray! Your content was shared to tha world")
          );
      } catch (error) {
        //alert(`Oops! I couldn't share to the world because: ${error}`);
      }
    } else {
      // fallback code
      // alert(  "Web share is currently not supported on this browser. Please provide a callback");
    }

  }

  const cancelDataToUpdate = () => {

    setDataToUpdate({ ...dataToUpdate, label: dataToUpdate.value })

    setDataToUpdate(null)
  }

  const incData = (d) => {

    var value = dataToUpdate.label == "-" ? -1 : parseInt("0" + dataToUpdate.label ?? 0) ?? 0;

    value += d;
    value = Math.max(-1, value);
    if (value === -1) value = "-"

    setDataToUpdate({ ...dataToUpdate, label: `${value}` })

  }

  const setDataToUpdate_ = (d) => {


    if (!d.is_live_mode && d.nature_id != 39) {
      console.log("No Data Live", d)
      var d_ = datas.find(d => d.nature_id == 39);
      if (d_) {

        d = { ...d_, dataRef: d }
      }
      else {
        return
      }
    }

    setDataToUpdate(d);
  }
  const addReportEvent = (d) => {

    console.log("addReportEvent", d);
    //  setDataToUpdate({ ...dataToUpdate, label: `${value}` })


    setDataToUpdate({ ...dataToUpdate, label: d.label })
  }


  const attributesToUpdate = useMemo(() => {
    if (!dataToUpdate || !attributes) return
    var data = dataToUpdate.dataRef ?? dataToUpdate
    if (!attributes[data.nature_id]) return

    return attributes[data.nature_id] && attributes[data.nature_id]
      .filter(d => !d.parent_id)
      .map(attr => {
        return {
          ...attr,
          variant: parseInt(attributes[data.nature_id].find(dc => dc.parent_id == attr.data_id).label),
          label: data.data_id != dataToUpdate.data_id ? data.label + " " + attr.label : attr.label
        }
      })
  }, [dataToUpdate])


  async function setStartShow(value){
    setShowLoader(true)
    console.log(value.toISOString())
  var body={ show_id: show.show_id, start_at : value.toISOString().substring(0,15) }
  console.log( value.toISOString().substring(0,10) ,show.end_at  )
  setChooseTime(false)
  if(  !show.end_at ||  show.end_at===""  || value.toISOString()  > show.end_at  )body.end_at = value.toISOString().substring(0,11)+"23:59"
    var result = await project.api("/update_show", { body})
   
 await  resetLive(show.data_id)
  setChooseTime(true)
  setShowLoader(false)
  }
  async function setEndShow(value){
    setShowLoader(true)
    setChooseTime(false)
    console.log(value.toISOString())
    var result = await project.api("/update_show", { body: { show_id:show.show_id, end_at : value.toISOString().substring(0,15)  } })

   await resetLive(show.data_id)
    setChooseTime(true)
    setShowLoader(false)
  }
  

const setGeoHash = async (geohash) => {
  setShowLoader(true)
setChooseMap(false);
  var result = await project.api("/set_geohash", { body: { path: show.path, ...geohash    } })
  console.log(result)
  await resetLive(show.data_id)
  setShowLoader(false)
}



  return <>
   {showLoader && <Loader />}
    {chooseMap && <FormMap close={() => setChooseMap(false)} valideGeoHash={ isOwner &&(  (geohash, lat, lng) => setGeoHash({ geohash, lat, lng }))} geohash={show.geohash } />}
   
   {slug && show && show.slug != slug ? <></> :
    <>
    
      <div className={" mb-0 w-full flex flex-col overflow-hidden " + className + (standAlone ? " sticky -top-2 z-10 " : "")} >
        {live.show_id &&
       
            <div className={' text-white p-2 px-4 text-base font-bold flex flex-row w-full bg-zinc-800  justify-between '}  >
              {!standAlone && <span>{cover?.label}</span>}
              {standAlone && <span onClick={() => navigate(   location.key !== "default" ? -1:"/")  } ><FontAwesomeIcon icon={fa.faChevronLeft} className={'   text-xl px-1 '} /></span>}
              <span   onClick={()=>setChooseMap(true)}>  {show.geocontent && show.geocontent.city}<FontAwesomeIcon icon={fa.faLocationDot} className={'  text-red-600 text-color text-xl px-1 '} /></span>
            </div>  }

{ false && 
            <div className={' w-full  relative '} >
              <div className={' top-0 left-0 w-full bg-red-600 p-4 text-4xl font-bold flex flex-row items-center justify-center italic text-white '}    style={{  clipPath:"polygon(0 0, 55% 0, 45% 100%, 0% 100%)" }}  >Live <span className='mx-20 text-white'> My </span> Game</div>
              <div className={'absolute top-0 right-0 w-full bg-transparent  p-4 text-4xl font-bold flex flex-row items-center justify-center italic text-white '}   style={{  clipPath:"polygon(55% 0, 100% 0, 100% 100%, 45% 100%)" }} >Live <span className='mx-20 text-white'> My </span> Game</div>
          </div>   }
        <div className="w-full text-white  " onClick={live.show_id && !owner_id && show.slug && slug !== show.slug ? () => { navigate("/live/" + show.slug) } : null} style={{
          display: "grid",

          background: ("transparent" ?? content.color ?? "#000"), height: (100 * parseInt(content.rows) / parseInt(content.cols)) + "vw"
        }}  >
          {
            Array((parseInt("0" + content.rows ?? 1) ?? 2) * (parseInt("0" + content.cols ?? 1) ?? 2)).fill({ a: 0 }).map((row, index) => {
              var indexCol = index % (parseInt("0" + content.cols ?? 1) ?? 2);
              var indexRow = Math.floor(index / (parseInt("0" + content.cols ?? 1) ?? 2));
              var cell = inCell(indexCol, indexRow)

              if (cell && (cell.r0 !== indexRow || cell.c0 !== indexCol)) return
              return <div className=' p-0 flex flex-row items-center justify-center '
                onClick={() => {
                  console.log("updatableDatas", updatableDatas, cell?.key)
                  if (diffusingReport && (owner_id || standAlone) && cell && !dataToUpdate && updatableDatas[cell.key]) {

                    window.scrollTo(0, 0, { behavior: "smooth" });
                    setDataToUpdate_(updatableDatas[cell.key]);

                  }
                  if (!diffusingReport && owner_id && cell && !labelToUpdate && updatableLabels[cell.key]) {
                    window.scrollTo(0, 0, { behavior: "smooth" });
                    setLabelToUpdate(updatableLabels[cell.key]);
                  }
                }}
                style={{
                  background: (cell?.bg ?? "inherit"),
                  border: (cell && updatableDatas[cell.key] && updatableDatas[cell.key]?.data_id == dataToUpdate?.data_id) ||
                    (cell && updatableLabels[cell.key] && updatableLabels[cell.key]?.data_id == labelToUpdate?.data_id) ? " 4px solid #FF5 " : "",
                  gridColumnStart: 1 + indexCol, gridColumnEnd: (cell ? cell.c1 + 2 : 1 + indexCol),
                  margin: cell?.padding ? 0.5 * cell.padding + "vw" : 0,
                  borderRadius: cell?.rounding ? cell.rounding + "vw" : 0,
                  width: (100 * ((cell ? cell.c1 - cell.c0 : 0) + 1) / parseInt(content.cols) - (cell?.padding ? cell.padding : 0)) + "vw",
                  height: (100 * ((cell ? cell.r1 - cell.r0 : 0) + 1) / parseInt(content.cols) - (cell?.padding ? cell.padding : 0)) + "vw",
                  color: (cell?.color ?? "inherit"),
                  gridRowStart: (indexRow + 1), gridRowEnd: (cell ? (cell.r1 + 2) : indexRow + 1)

                  , boxSizing: "border-box"
                }} key={index}>
                {cell && computedTexts[cell.key]?.substr(0, 4) !== "IMG:" && <span className={" text-center  w-full  truncate " + (newUpdateData && newUpdateData.data_id === updatableDatas[cell.key]?.data_id ? " animate-bounce " : "")} style={{ fontSize: (40 * ((cell ? cell.r1 - cell.r0 : 0) + 1) / parseInt(content.cols)) + "vw" }}  >{
                  computedTexts[cell.key]}
                </span>}
                {cell && computedTexts[cell.key]?.substr(0, 4) == "IMG:" && <img className={"  w-full  h-full object-cover "} src={computedTexts[cell.key].substr(4)} />}
              </div>
            }
            )
          }

        </div>



        {live.show_id &&
          <div className=' p-1   text-white items-center  flex flex-row w-full  justify-between'>
            {
              <div className='    rounded-full font-bold flex flex-row   items-center justify-center'>

                <img onClick={() => setShowUser({ user_id: live.owner_id, username: live.username, quality: "Éditeur" })} src={project.urlImage("img_" + live.owner_id)} className='  w-8 h-8 rounded-full m-1 ' />
                <div className=' p-1 px-2 rounded-full m-1 mx-2 flex flex-row   items-center justify-center' onClick={authUser && authUser.user_id != live.owner_id ? likeShow : null} ><FontAwesomeIcon icon={isLike ? fa.faHeart : fa.faHeart} className={'mr-1 text-xl' + (isLike ? " text-red-600" : "")} /> {likeCount}</div>
              </div>
            }
            {isMobile && <div onClick={shareLive} className='border-b border-white' >  Partager  <FontAwesomeIcon icon={fa.faShare} className='text-white text-xl hidden' /> </div>}

{  authUser?.user_id == 1  && <Link to={ "/path/" + show.path }  ><FontAwesomeIcon icon={fa.faDatabase} className='text-white text-xl ' /></Link>}
{  isOwner && <FontAwesomeIcon icon={fa.faClock} className='text-white text-xl ' onClick={()=>setChooseTime(!chooseTime)} />}
            <div className='  m-1  ml-4 rounded-full font-bold flex flex-row   items-center justify-center'>


              {(!diffusingReport || status == "FINI") && (!canDiffuse || status !== "EN DIRECT") && <div className=' p-1 px-2 rounded-full m-1 mx-2 font-bold bg-white  text-zinc-800 text-base flex flex-row   items-center justify-center'    >  {status}   </div>}
              {canDiffuse && status == "EN DIRECT" && <div className=' p-1 px-2 rounded-full m-1 mx-2 font-bold bg-green-700 text-white text-base flex flex-row   items-center justify-center' onClick={() => reportStart(show.data_id)}   > <FontAwesomeIcon icon={fa.faBroadcastTower} className='text-white  mx-1' /> Diffuser   </div>}
              {diffusingReport && status == "EN DIRECT" && <div className=' p-1 px-2 rounded-full m-1 mx-2 font-bold bg-red-700 text-white text-base flex flex-row   items-center justify-center' onClick={() => reportStop(show.data_id, diffusingReport.report_id)}    > <FontAwesomeIcon icon={fa.faBroadcastTower} className='text-white  mx-1' /> Stop    </div>}
            </div>
          </div>}

      </div>
{ chooseTime && <div   className={ className +' p-4 bg-slate-300  '  }>
  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr" >




<div className=' m-1 w-full bg-white  rounded  '>

  <DateTimePicker className=' p-2 m-1 w-full text-white ' defaultValue={show.start_at ? dayjs(show.start_at) : null} label="DÉBUT" onAccept={(newValue) => setStartShow(newValue)} />

</div>



<div className=' mt-4 m-1 w-full  bg-white rounded'>

  <DateTimePicker className=' p-2 m-1 w-full text-white ' label="FIN" defaultValue={show.end_at ? dayjs(show.end_at) : null} onAccept={(newValue) => setEndShow(newValue)} />

</div>
</LocalizationProvider>
</div>
}



      {!diffusingReport && labelToUpdate &&
        <div className={" mb-4 p-1 w-full flex flex-col justify-center items-center " + className} >

          <div className=' flex flex-row w-full mt-2 p-2 justify-between' >
            <input id={"label_" + labelToUpdate.data_id} className='mx-4 p-4 font-bold text-black bg-white flex-grow  text-base text-center ' type="text" defaultValue={labelToUpdate.label}
              onChange={() => setLabelToUpdate({ ...labelToUpdate, label: document.getElementById("label_" + labelToUpdate.data_id).value })} />
          </div>

          <div className=' flex flex-row w-full mt-2 p-2 justify-center' >
            <div className='p-4 font-bold bg-red-800 text-white rounded-md' onClick={() => setLabelToUpdate(null)} >ANNULER</div>
            <div className='p-4 font-bold bg-green-800 text-white  rounded-md' onClick={() => { updateData(labelToUpdate, live); setLabelToUpdate(null) }}  >VALIDER</div>
          </div>
        </div>}

      {
        diffusingReport && dataToUpdate &&
        <div className={" mb-4 p-1 w-full flex flex-col justify-center items-center " + className} >

          {dataToUpdate.is_live_mode && <div className=' flex flex-row w-full mt-2 p-2 justify-between' >
            <div className='p-4 font-bold bg-red-800 text-white rounded-md' onClick={cancelDataToUpdate} >ANNULER</div>
            <div className='p-4 px-6 font-bold text-black bg-white rounded-lg' onClick={() => incData(-1)} >-</div>
            <div className='p-4 font-bold text-black bg-white'  >{dataToUpdate.label}</div>
            <div className='p-4 px-6 font-bold text-black bg-white rounded-lg' onClick={() => incData(+1)} >+</div>
            <div className='p-4 font-bold bg-green-800 text-white  rounded-md' onClick={() => { updateData(dataToUpdate, live, diffusingReport); setDataToUpdate(null) }}  >VALIDER</div>
          </div>
          }


          {attributesToUpdate &&
            attributesToUpdate.map((d, i) => <div className='m-1 relative text-center w-2/3  p-3 px-6 font-bold text-black bg-white rounded-lg' onClick={() => {
              d.variant ? incData(d.variant)
                : addReportEvent(d)
            }
            } key={i} >{d.label}<FontAwesomeIcon className='hidden absolute -right-8 top-0 text-white text-2xl' icon={fa.faInfoCircle} /></div>)
          }
          {!dataToUpdate.is_live_mode &&
            <><div className=' bg-green-800  m-1 relative text-center w-2/3  p-3 px-6 rounded-lg font-bold text-white ' onClick={() => { updateData(dataToUpdate, live, diffusingReport); setDataToUpdate(null) }}  >VALIDER</div>
              <div className='m-1 relative text-center w-2/3  p-3 px-6 rounded-lg font-bold bg-red-800 text-white ' onClick={cancelDataToUpdate} >ANNULER</div>
            </>
          }

        </div>}
        
      {
        standAlone &&
        <>
           <div className={" mb-4 w-full flex flex-col  " + className} >


    <div className=' p-1  font-bold  text-white items-center  flex flex-row w-full  justify-center' >


      <div className='  p-1 px-2 rounded-full m-1 mx-2 font-bold  text-white  flex flex-row   items-center justify-center'>Activer les Notifications <SwitchButton value={subscribe==="1"?1:null}  changeValue={subscribeSwitch } classNameOff={""} classNameBarOn={" bg-blue-800 "} /> </div>




    </div>
</div>
         
         
         
          <div className={" mb-4 w-full flex flex-col  " + className} >

            {show.reports &&
              show.reports.map((report, i) =>
                <div className=' p-1  font-bold  text-white items-center  flex flex-row w-full  justify-between' key={i}>




                  <div className=' p-1 px-2 rounded-full m-1 mx-2 flex flex-row   items-center justify-center' onClick={() => viewReport(report)} ><FontAwesomeIcon icon={!isUnWantedReport(report) ? fa.faEye : fa.faEyeSlash} className={'mr-1 text-xl' + (isViewReport(report) || isUnWantedReport(report) ? "  " : "   opacity-40 ")} /><span className=' whitespace-nowrap'> {viewReportCount(report)}</span></div>

                  <div className='hidden  p-1 px-2 rounded-full m-1 mx-2 font-bold  text-white  flex flex-row   items-center justify-center'>Suivre <SwitchButton value={true} classNameOff={""} classNameBarOn={" bg-blue-800 "} /> </div>

                  <div className='  m-1  ml-4 rounded-full font-bold flex flex-row text-sm  items-center justify-center' onClick={() => setShowUser({ ...report.user, user_id: report.owner_id, quality: "Reporter" })} >
                    @{report.user?.username}
                    <img src={project.urlImage("img_" + report.owner_id)} className='  w-8 h-8 rounded-full m-1 ' />
                  </div>


                </div>)
            }</div>


          {false && standAlone && currentGeoHash &&
            <div className={" mb-4 w-full flex flex-col  " + className} >
              <div className=' p-2 text-base font-bolxd text-white items-center  flex flex-row w-full  justify-between'>


                {currentGeoHash && show.geohash && <span className=' text-xs m-1' > ({getDistanceFromLatLonInKm(currentGeoHash, show.geohash)}m ) </span>}
              </div>
            </div>}


          {standAlone && comments &&
           <Comments    comments={comments} className={className} computedComments={computedComments} setShowUser={setShowUser} authUser={authUser} project={project} standAlone={standAlone}  isReporter={isReporter}/> 
            }






          {standAlone && authUser &&
 <div className={" px-4 bg-black w-full flex flex-col  items-start  justify-center  fixed  bottom-0 p-4 "} >
         
              {showSuggestions && 
               <div className={"  w-full flex flex-wrap  items-start  justify-start  "} >{suggestions.map(suggestion=>
              
              <div key={suggestion}   className=' bg-orange-600   text-white m-1 p-1 rounded' onClick={()=>{var cinput = document.getElementById("new_comment"); cinput.value=(cinput.value +" " + suggestion).trim();setTimeout(()=> cinput.focus(),10)}   }>{ suggestion}</div>
              )  }</div>  }
              <div className={"  bg-black w-full flex flex-row  items-center  justify-center  "} >
              <input id="new_comment" placeholder='Envoie ton commentaire' className=' text-white flex-grow  m-2 rounded-lg bg-transparent border border-zinc-500 p-2'  onFocus={()=>setShowSuggestions(true)}  onBlur={()=>{setTimeout(()=> setShowSuggestions(false),10) }}   />
              <div className='m-2 p-2 font-bold bg-blue-800 text-white  rounded-md' onClick={newComment}  > Envoyer</div> </div>
              </div>
          }
        </>
      }



      {owner_id &&
        <div className=' p-1 pt-1 mb-6 text-white items-center text-xs  bg-zinc-700 mx-4  flex flex-row rounded-b-xl justify-between'>


          <div className='    rounded-full font-bold flex flex-row   items-center justify-center' onClick={() => deleteLive(live.data_id)} >
            <div className='  px-2 rounded-full m-1 mx-2 flex flex-row   items-center justify-center'><FontAwesomeIcon className=' text-2xl mr-2 ' icon={fa.faClose} /> Supp.</div>
          </div>

          <div className='    rounded-full font-bold flex flex-row   items-center justify-center' onClick={() => resetLive(live.data_id)} >
            <div className='  px-2 rounded-full m-1 mx-2 flex flex-row   items-center justify-center'><FontAwesomeIcon className=' text-2xl mr-2 ' icon={fa.faRecycle} /> Reset</div>
          </div>

          <div className='    rounded-full font-bold flex flex-row   items-center justify-center'    >
            <SwitchButton value={live.active} changeValue={(value) => { updateShow({ show_id: live.show_id, active: value ? 1 : 0 }) }} /></div>

          {live.active ?
            <Link to={"/live/" + live.slug}><div className={'  rounded-full font-bold flex flex-row   items-center justify-center px-2'} >Ouvrir
              <div className='  rounded-full m-1  flex flex-row   items-center justify-center'><FontAwesomeIcon className=' text-2xl mr-2 ' icon={fa.faChevronRight} /> </div>
            </div></Link>
            : <></>}


        </div>}





    </>
  }</>

    ;
}

export default DataLive;
