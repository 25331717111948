

import { useContext, useEffect, useMemo, useState } from 'react';
import { AutoTextSize } from 'auto-text-size';
import DataLabel from './DataLabel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { MfctContext } from '../context';



const __CONSTANTS_DESIGNS=["status_step","status_comment"];

function getRandomHexColor() {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}



function FormDesign({ nature, data, natures, updateNatures, UseDesign }) {

  const {project} = useContext(MfctContext )
  const [content, setContent] = useState({})
  const [datas, setDatas] = useState([])
  const [level, setLevel] = useState(0)
  const [designs, setDesigns] = useState([]);
  const [design, setDesign] = useState(null);
  const [selectedCell, setSelectedCell] = useState(null)

  const [selectedKey, setSelectedKey] = useState(null)
  useEffect(() => {
    if (level > 0) getDatas()
  }, [level])

  useEffect(() => {

    setContent(design?.content)


  }, [design])
  const getDesigns = async () => {

    var result = await project.api("/get_designs", { body: { nature_id: data.nature_id } })
    console.log("setDatas", result);
    setDesigns(result);

  }
  useEffect(() => {
    getDesigns();
  }, [])

  const selectDesign = (design_id) => {
    setContent(null)
    var d = design_id ? (design_id == "new" ? { content: { design: {}, rows: 10, cols: 20 } } : designs.find(d => d.design_id == design_id)) : null;
    if (d) d.is_default_design = nature.default_design_id == d.design_id;
    if (d) d.use_design = data.design_id == d.design_id;
    setDesign(d);


  }




  const selectCell = (col, row) => {

    var cell = inCell(col, row);
    if (cell) {
      setSelectedKey(cell.key);
      return;
    }



    if (selectedCell) {

      content.design[col + ' ' + row] = {
        key: col + ' ' + row,
        c0: Math.min(selectedCell.col, col),
        c1: Math.max(selectedCell.col, col),
        r0: Math.min(selectedCell.row, row),
        r1: Math.max(selectedCell.row, row),

        dynamic_label: [],
        bg: "",
        color: ""
      }
      setContent({ ...content })
      setSelectedCell(null)
      setSelectedKey(col + ' ' + row);
    }


    else {
      setSelectedCell({ col, row })
    }
  }

  useEffect(() => {



//

    if ((__CONSTANTS_DESIGNS.includes( selectedKey)) && !content.design[selectedKey]) {
   
      content.design[selectedKey] = {
        key: selectedKey,
        dynamic_label: [],
        bg:"",
        color: ""
      }

      setContent({ ...content })

    }
    
    if(datas.length==0)  setLevel(2);

  }, [selectedKey, content]);




  const inCell = (col, row) => {

    var cells = Object.values(content.design)
    for (var i = 0; i < cells.length; i++) {
      var cell = cells[i];
      if (col >= cell.c0 && col <= cell.c1 && row >= cell.r0 && row <= cell.r1)
        return cell;
    }
    return null;
  }

  const add_data = async (data_) => {
    var key = selectedKey

    if (data_.nature_id) {
      var relativePath = ""


      if (data.path.indexOf(data_.path)) {
        console.log("C'est dedans", data.path, data_.path)
        var dataIn = data_;
        var path = data_.path;
        while (path != data.path) {

          var parts = path.split("-")
          parts.pop();
          path = parts.join("-");


          var dataParent = datas.find(d => d.path === path)
          if (dataParent.is_collection) {
            var childs = datas.filter(d => dataParent.data_id === d.parent_id)
            var index = childs.findIndex(d => d.path == dataIn.path)
            relativePath = (dataIn.byRef ? ".*" : "") + "." + dataParent.label + "[" + index + "]" + relativePath

            parts.pop();
            path = parts.join("-");
            dataIn = datas.find(d => d.path === path)
          }
          else {
            var natureLabel = natures.find(n => n.nature_id == dataIn.nature_id).label
            relativePath = (dataIn.byRef ? ".*" : "") + "." + natureLabel + relativePath
            dataIn = dataParent;
          }



        }


      }
      else {

        var path = data.path;
        console.log("C'est dans les parents", path, data_.path)
        while (path != data_.path) {

          var parts = path.split("-")
          parts.pop();
          path = parts.join("-");

          relativePath += ".PARENT"
        }



      }
      console.log(relativePath)

      data_.relativePath = relativePath

    }
var dplus = { data_id: data_.data_id, label: data_.label, relativePath: data_.relativePath, nature_id: data_.nature_id, natureLabel: data_.natureLabel, natureColor: data_.natureColor }

 
    console.log("add_data", content, data_);
    content.design[key].dynamic_label.push(dplus);
    setContent({ ...content });

  }


const CursorEdit= ({kkey,index,data_})=>{

var cursor = datas.find(d=>d.nature_id==38);
if(!cursor) return <></>;
if(!data_.relativePath) return <></>;
var lists = []
var parts = data_.relativePath.split(".")
for(var i=0; i<parts.length; i++){
var subparts =parts[i].split("[")
  if(subparts.length>1){
lists.push ({ liste: subparts[0], cursor:"["+subparts[1] })
  }
}

if( lists.length== 0 ) return <></>

return <div className=' text-xs flex flex-col w-full' >{  lists.map((list,i)=>
  <div   key={i} className={' p-2 rounded w-full ' + (   data_.cursor &&  data_.cursor.liste==   list.liste? " font-bold  text-black bg-white  ":" bg-black text-white ")    }     onClick={()=>setCursor(kkey,index,list,cursor)} >
    <FontAwesomeIcon icon={faCheck}    className={ (   data_.cursor &&  data_.cursor.liste==   list.liste? " font-bold  text-green-700 ":" text-zinc-800 ")    } ></FontAwesomeIcon>  CURSOR { list.liste}  </div>) } </div>


}

const setCursor=(key ,index ,list,cursor)=>{
  console.log("setCursor",key,cursor)
  content.design[key].dynamic_label[index].cursor =(  content.design[key].dynamic_label[index].cursor  && 
    content.design[key].dynamic_label[index].cursor.liste ==list.liste  ) ? null:{...list,relativePath: cursor.relativePath };
  setContent({ ...content });

}


  const remove_key = async () => {
    var key = selectedKey
    delete content.design[selectedKey];
    setSelectedKey(null)
    setContent({ ...content });

  }

  const remove_data = async (i) => {
    var key = selectedKey
    content.design[key].dynamic_label = content.design[key].dynamic_label.filter((d, index) => i != index);
    setContent({ ...content });

  }
  async function getDatas() {
    console.log("getDatas");
    if (!project)
      return

    var result = await project.api("/get_datas_level/:path/:level", { args: { path: data.path, level: data.level + level } })
    console.log("setDatas", result);
    setDatas(result);

  }
  async function addRefInfo(data_, pathRef) {


    var result = await project.api("/get_datas_level/:path/:level", { args: { path: pathRef, level: "+1" } })


    var childs = result.filter(d => d.path.split(pathRef + "-").length > 1)
      .map(d => { return { ...d, parent_id: data_.data_id, byRef: true, path: d.path.replace(pathRef, data_.path) } })

    childs.forEach(child => {
      if (!datas.find(d => d.data_id == child.data_id))
        datas.push(child);
    })
    datas.sort((a, b) => a.path < b.path ? -1 : 1)

    //    console.log(pathRef, childs)
    setDatas([...datas])


  }


  const text_key = (key) => {

    return content.design[key].dynamic_label.map(d => d.label).join("")


  }
  const image_key = (key) => {

    if (content.design[key].dynamic_label.length !== 1) return
    if (content.design[key].dynamic_label[0].relativePath !== ".IMAGE") return
    return content.design[key].dynamic_label[0].label


  }
  const change_color = (id) => {

    var color = getRandomHexColor()
    document.getElementById(id).value = color

    if (id == "color_nature") setContent({ ...content, color });
    else changeValue(id)

  }
  const add_text = () => {
    var text = prompt(" Ajouter du texte ", " ");
    add_data({ label: text, natureColor: "#000", natureLabel: "TEXTE" })

  }
  const add_image = () => {
    var url = prompt(" Ajouter une image ", " ");
    add_data({ label: url, natureColor: "#000", natureLabel: "IMAGE" })
  }

  const changeFontSize = async () => {

    setContent({ ...content, fontSize: document.getElementById("fontSize").value ?? 1 });
  }


  const changeCols = async () => {

    setContent({ ...content, cols: document.getElementById("cols").value ?? 1 });
  }

  const changeRows = async () => {
    setContent({ ...content, rows: document.getElementById("rows").value ?? 1 });
  }

  const changeValue = async (id) => {
    content.design[selectedKey][id] = document.getElementById(id).value

    setContent({ ...content });
  }



  const save_infos = async () => {

    design.content = { ...content }
    var result = await project.api("/update_design", {
      body: {
        ...design, label: document.getElementById("label_design").value ?? "DESIGN " + data.label, nature_id: data.nature_id,

        is_default_design: document.getElementById("is_default_design").checked,
        is_public: document.getElementById("is_public").checked
      }
    })




    if (!design.design_id && result.design_id) {
      setDesigns({ ...designs, result });
      selectDesign(result.design_id)

    }
    else {
      setDesigns(designs.map(d => d.design_id == result.design_id ? result : d));

    }


    updateNatures();



  }

  const cancel_infos = async () => {

    await updateNatures();

  }

  return (<div className=" border-1 p-6  w-full flex flex-col" >
    <div className=" border-1 p-1  w-full flex flex-row"  >
      {nature.label}
    </div>


    <select id="sdesign" className='w-full p-1 border-2 rounded border-gray-500 border '

      onChange={() => { selectDesign(document.getElementById("sdesign").value) }}
    >
      <option value={null}  > -- Sélectionner design </option>
      {
        designs.map(d =>
          <option value={d.design_id} key={d.design_id} >  {d.label}  </option>
        )

      }
      <option value={"new"}  > NOUVEAU DESIGN  </option>
    </select>

    {
      design && content && <><span className='text-xs' >Label </span>
        <input id="label_design" className='w-full p-1 rounded border-gray-500 border ' placeholder='Saisir Label' defaultValue={design.label} />

        {
          design.design_id &&
          <div className='flex flex-row  items-center m-1 justify-start'><span className=' font-bold ' >Utiliser ce design  </span>
            <input id="use_design" type="checkbox" onChange={() => { UseDesign(document.getElementById("use_design").checked ? design.design_id : 0) }} defaultChecked={design.design_id == data.design_id}  ></input>

          </div>
        }

        <div className='flex flex-row  items-center m-1 justify-start'><span className='text-xs' >Couleur </span>
          <input id="color_nature" className=' mx-2 p-1  rounded border-gray-500 border ' placeholder='Saisir Couleur' defaultValue={nature.color} onChange={change_color} />
          <button className="p-1  rounded-full mt-1 mx-2 text-bleu-500 font-bold" onClick={change_color}> Changer couleur </button>
        </div>

        <div className='flex flex-row  items-center m-1 justify-start'><span className=' font-bold ' >Désign par défaut </span>
          <input id="is_default_design" type="checkbox" defaultChecked={design.is_default_design}  ></input>

        </div>

        <div className='flex flex-row  items-center m-1 justify-start'><span className=' font-bold ' >Public</span>
          <input id="is_public" type="checkbox" defaultChecked={design.is_public}  ></input>

        </div>
{

__CONSTANTS_DESIGNS.map(keyS=>

        <div className='flex flex-col w-full  items-center m-1 justify-start' key={keyS}>
          <span className=' font-bold ' >{keyS.split("_").map( word=>  word.charAt(0).toUpperCase()+ word.slice(1)  ).join(" ") }</span>
          <div className=" flex overflow-x-auto text-xs items-start justify-start" onClick={() => setSelectedKey(keyS)} >
            {(content.design[keyS]?.dynamic_label)?.map((d, i) =>
              <DataLabel data={{ ...d, natureLabel: d.relativePath }} natures={natures} noRef={true} />

            )}
            {(!content.design[keyS] ||
              content.design[keyS].dynamic_label.length == 0) && <DataLabel data={{ label: " PAS DE TEXTE " }} natures={natures} nonature={true} noRef={true} />}
          </div>
        </div>
        )

}

        <span className='text-xs' >Rows </span>
        <input id="rows" type="number" className='w-full p-1 rounded border-gray-500 border ' placeholder='Saisir Rows' defaultValue={content.rows} onChange={changeRows} />
        <span className='text-xs' >Cols </span>
        <input id="cols" type="number" className='w-full p-1  rounded border-gray-500 border ' placeholder='Saisir Cols' defaultValue={content.cols} onChange={changeCols} />



 
        <div className="w-full -ml-6  bg-black text-white scale-95" style={{ display: "grid", height: (100 * parseInt(content.rows) / parseInt(content.cols)) + "vw" }}  >
          {
            Array((parseInt("0" + content.rows ?? 1) ?? 2) * (parseInt("0" + content.cols ?? 1) ?? 2)).fill({ a: 0 }).map((row, index) => {
              var indexCol = index % (parseInt("0" + content.cols ?? 1) ?? 2);
              var indexRow = Math.floor(index / (parseInt("0" + content.cols ?? 1) ?? 2));
              var cell = inCell(indexCol, indexRow)

              if (cell && (cell.r0 !== indexRow || cell.c0 !== indexCol)) return <></>
              return <div className=' flex flex-row items-center justify-center'
                onClick={() => selectCell(indexCol, indexRow)}
                style={{
                  gridColumnStart: 1 + indexCol, gridColumnEnd: (cell ? cell.c1 + 2 : 1 + indexCol),
                  margin :  cell?.padding ? 0.5*cell.padding + "vw":0,
                  borderRadius :  cell?.rounding ? cell.rounding + "vw":0,
                  width: (100 * ((cell ? cell.c1 - cell.c0 : 0) + 1) / parseInt(content.cols)- (cell?.padding ? cell.padding :0) ) + "vw",
                  height: (100 * ((cell ? cell.r1 - cell.r0 : 0) + 1) / parseInt(content.cols)- (cell?.padding ? cell.padding :0)) + "vw",
                  color: (cell?.color ?? "inherit"),
                  gridRowStart: (indexRow + 1), gridRowEnd: (cell ? (cell.r1 + 2) : indexRow + 1),
                   border: cell && cell.key== selectedKey ? "3px solid #FAA":   "1px solid #FFF3", 
                   background: cell?.bg ?? "#000"
                  , boxSizing: "border-box"
                }} key={index}>

                {cell && !image_key(cell.key) && <span className="px-2 text-center  w-full  truncate  whitespace-nowrap" style={{ fontSize: (40 * ((cell ? cell.r1 - cell.r0 : 0) + 1) / parseInt(content.cols)) + "vw" }}  >{
                  text_key(cell.key)}

                </span>}
                {cell && image_key(cell.key) && <img className="w-full h-full object-cover  " src={image_key(cell.key)} />}
              </div>
            }
            )
          }

        </div>

        {selectedKey && content.design[selectedKey] &&  (content.design[selectedKey].c1 || content.design[selectedKey].r1)  &&

          <>    <button className="p-1 rounded-full mt-1 bg-red-500 text-white font-bold" onClick={remove_key}> del</button>
            

            <div className='flex flex-row  items-center m-1 justify-between'>
              {["c0", "c1", "r0", "r1"].map((c) =>
                <div className='flex flex-col  items-center m-1 justify-start' key={c}>
                  <span className='text-xs' >{c}</span>
                  <span className='font-bold' >{content.design[selectedKey][c]} </span>

                  <div className='flex flex-row  items-center m-1 justify-start'>
                    <button className="p-1 w-6 rounded-full mt-1 mx-2 bg-red-500 text-white font-bold" onClick={() => { content.design[selectedKey][c]--; setContent({ ...content }) }}> - </button>
                    <button className="p-1 w-6 rounded-full mt-1 mx-2 bg-green-500 text-white font-bold" onClick={() => { content.design[selectedKey][c]++; setContent({ ...content }) }}> + </button>
                  </div>
                </div>)
              }
            </div>
            <div className='flex flex-row  items-center m-1 justify-center'>
              {[["up","-",["r0","r1"]],[ "down","+",["r0","r1"]], ["left","-",["c0","c1"]],[ "right","+",["c0","c1"]]].map((c) =>
                <div className='flex flex-col  items-center m-1 justify-start' key={c[0]}>
                 

                  <div className='flex flex-row  items-center justify-start'>
                        <button className="p-1 rounded-full mt-1 p-2 bg-blue-500 text-white font-bold" onClick={() => {
                           if(c[1]=="+")
                           {
                             content.design[selectedKey][c[2][0]]++;
                             content.design[selectedKey][c[2][1]]++;
                           }
                           else{
                            content.design[selectedKey][c[2][0]]--;
                            content.design[selectedKey][c[2][1]]--;
                           }
                            setContent({ ...content }) }}> {c[0]} </button>
                  </div>
                </div>)
              }
            </div>
            <div className='flex flex-row  items-center m-1 justify-start'><span className='text-xs' >Couleur Texte </span>
              <input id="color" className=' mx-2 p-1 border-2 rounded border-gray-500 border ' placeholder='Saisir Couleur' defaultValue={content.design[selectedKey].color} onChange={() => { changeValue("color") }} />
              <button className="p-1  rounded-full mt-1 mx-2 text-bleu-500 font-bold" onClick={() => change_color("color")}> Changer couleur </button>
            </div>

            <div className='flex flex-row  items-center m-1 justify-start'><span className='text-xs' >Couleur Fond </span>
              <input id="bg" className=' mx-2 p-1 border-2 rounded border-gray-500 border ' placeholder='Saisir Couleur FOnd' defaultValue={content.design[selectedKey].bg} onChange={() => { changeValue("bg") }} />
              <button className="p-1  rounded-full mt-1 mx-2 text-bleu-500 font-bold" onClick={() => change_color("bg")}> Changer couleur </button>
            </div>
            <div className='flex flex-row  items-center m-1 justify-start'><span className='text-xs' >Padding </span>
              <input id="padding" className=' mx-2 p-1 border-2 rounded border-gray-500 border ' placeholder='Saisir Padding' defaultValue={content.design[selectedKey].padding} onChange={() => { changeValue("padding") }} />
           
            </div>
            <div className='flex flex-row  items-center m-1 justify-start'><span className='text-xs' >Rounding </span>
              <input id="rounding" className=' mx-2 p-1 border-2 rounded border-gray-500 border ' placeholder='Saisir Arrondi' defaultValue={content.design[selectedKey].rounding} onChange={() => { changeValue("rounding") }} />
           
            </div>
            </>
            
            
            }

        {selectedKey && content.design[selectedKey] &&

          <>

 <span className='text-xs' >Label Dynamique </span>
            <div className=" flex overflow-x-auto text-xs items-start justify-start">
              {(content.design[selectedKey].dynamic_label).map((d, i) =>
                <DataLabel data={{ ...d, natureLabel: d.relativePath }} natures={natures} noRef={true} onClick={() => { remove_data(i) }} ><CursorEdit 
                index={i} data_={d}   kkey={selectedKey} 
                />
</DataLabel>
              )}
              {content.design[selectedKey].dynamic_label.length == 0 && <DataLabel data={{ label: " PAS DE TEXTE " }} natures={natures} nonature={true} noRef={true} />}
            </div>
            <div className='w-full p-1 border-2 rounded border-gray-500 border text-xs'>
              {
                datas.filter(data_=>!data_.is_collection).map(data_ => <>
                  <span key={data_.data_id}><DataLabel data={data_}  natures={natures} nonature={true} onClick={() => add_data(data_)} onClickRef={(reference_id, pathRef) => addRefInfo(data_, pathRef)} /></span>
                  {data_.childs &&
                    data_.childs.map(child =>
                      <span key={child.data_id}><DataLabel data={child} natures={natures} nonature={true} onClick={() => add_data(child)} /></span>
                    )
                  } </>)
              }

              {level > 0 && <DataLabel data={{ label: "AJOUTER DU TEXTE" }} nonature={true} onClick={() => add_text()} noRef={true} />}

              {level == 0 && <DataLabel data={{ label: "CHARGER DONNÉES ..." }} nonature={true} onClick={() => setLevel(2)} noRef={true} />}
              {level > 0 && <DataLabel data={{ label: "CHARGER PLUS DE DONNÉES ..." }} nonature={true} onClick={() => setLevel(level + 1)} noRef={true} />}
            </div>

          </>}

        <button className="p-1 w-full rounded-full mt-1 text-green-500 font-bold" onClick={save_infos}> Sauver</button>
        <button className="p-1 px-2 rounded-full  text-red-500 font-bold" onClick={cancel_infos} >Annuler</button>

      </>
    }
  </div>

  );
}

export default FormDesign;
