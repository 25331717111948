

import {  useMemo } from 'react';
import { Outlet } from 'react-router-dom';


function DataLabel({data, natures, nonature,suffix,className, onClick, noRef,onClickRef,children, childrenOnLeft}) {


const natureLabel = useMemo(()=>{
  if( data.natureLabel)
  return data.natureLabel
if( natures){
var n = natures.find(n0=>n0.nature_id== data.nature_id )
if( n) return     (n.specificity_id ? n.specificity_id +"/ ":"") +n.label
}
return data.natureLabel?? "unknown"; 

},[data,natures])
const natureColor = useMemo(()=>{
  if( natures){
  var n = natures.find(n0=>n0.nature_id== data.nature_id )
  if( n) return n.color ?? "#000"
  }
  return data.natureColor??"#000" ; 
  
  },[data,natures])

  return (
     !data.is_collection ?
     <div className={'border-4 p-1 rounded  m-1 flex flex-col text-white justify-center items-center ' + className} style={ {borderColor: natureColor, background: natureColor }  }>
    <div className='flex flex-row w-full justify-between items-start' style={ {borderColor: natureColor, background: natureColor }  }>
     
     
     { !noRef && data.reference_id &&
       <div  onClick={()=>onClickRef ? onClickRef(data.reference_id,data.content.pathRef): null} className=' flex flex-col bg-white justify-center items-center w-6 h-6 rounded-full' style={ {color: natureColor } } >*</div>
     }
     {childrenOnLeft && children}
      <div   className=' flex flex-col text-white justify-center items-end w-full'  >
    <span  onClick={onClick} className='p-0 m-0  font-bold ' >{data.label+ (suffix??"" )}</span>
 { !nonature &&   <span  className='p-0 m-0   text-xs'> {  natureLabel  }{data.is_collection ? "S":"" } {data.is_reference ? " *":"" } </span>}
    </div></div>

    {!childrenOnLeft && children}
    </div>
:  

<div className={ 'border-4 p-1 rounded  m-1 flex flex-col bg-white justify-center items-end' + className} style={ {borderColor: natureColor,color: natureColor }  }>
      <div className='flex flex-row w-full justify-between items-start'  >
      {childrenOnLeft ? children: <span  onClick={onClick} className='p-0 m-0  font-bold ' > </span> }
<span   onClick={onClick}  className=' p-0 m-0  font-bold ' >{data.label + (suffix??"" )}</span>
</div>
{ !nonature &&  <span  className=' p-0 m-0    text-xs'> {  natureLabel  }{data.is_collection ? "S":"" } {data.is_reference ? " *":"" }</span>}


{!childrenOnLeft && children}
</div>





  );
}

export default DataLabel;
