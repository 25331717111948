
import './App.css';

import './Live.css';
import { useEffect, useState, useMemo, useRef, useContext } from 'react';
import { projectInit, setToken, getAuthUser } from './service/mftc-api';
import Auth from './Auth';
import DataLabel from './components/DataLabel';
import ChooseNature from './components/ChooseNature';
import ButtonApi from './components/ButtonApi';
import FormApi from './components/FormApi';
import { useParams } from 'react-router';
import FormNature from './components/FormNature';
import FormAddData from './components/FormAddData';
import FormRef from './components/FormRef';
import FormDataLabel from './components/FormDataLabel';
import FormDesign from './components/FormDesign';
import DataLive from './components/DataLive';
import { socket } from './service/socket'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as fa from '@fortawesome/free-solid-svg-icons'
import Geohash from 'latlon-geohash';
import { isMobile } from 'react-device-detect';
import Header from './components/Header';
import Footer from './components/Footer';
import Loader from './components/Loader';
import UserProfile from './components/UserProfile';
import { Link } from 'react-router-dom';
import { MfctContext } from './context';

function Live({ displayOne,owner_id }) {

  var _lives = [];
  const { slug } = useParams();

  const { project, authUser,setAuthUser} = useContext (MfctContext);

  const [lives, setLives] = useState([]);



  const [activeEndpoint, setActiveEndpoint] = useState(null)
  const [isConnected, setIsConnected] = useState(socket.connected);
  const [fooEvents, setFooEvents] = useState([]);

  const [newUpdateData, setNewUpdateData] = useState(null)
  const [natures, setNatures] = useState([])
  const [currentGeoHash, setCurrentGeoHash] = useState();
  const [sortGeoHash, setSortGeoHash] = useState(true);
  const [showLoader,setShowLoader]=useState();
const [noLive,setNoLive]=useState(false);
const [showUser,setShowUser] = useState(null);
const  [disabled,setDisabled]=useState(false)
  const showParents = useMemo(() => {
    return lives.filter(l => l.show_id).length > 1;
  }, [lives])

  async function getNatures() {
    if (!project)
      return

    var result = await project.api("/get_natures")

    setNatures(result);

  }




  useEffect(() => {
    getNatures();

  }, [project]);


  useEffect(() => {

    console.log('changeLives', lives)

  }, [lives]);





  function onConnect() {

    setIsConnected(true);
  }

  function onDisconnect() {
    setIsConnected(false);
  }

  useEffect(() => {

    if (isMobile) {
      navigator.geolocation.getCurrentPosition(function (position) {

        var newG = Geohash.encode(position.coords.latitude, position.coords.longitude, 12)
        if (newG != currentGeoHash)
          setCurrentGeoHash(newG);




      })
    }
  }, [])


  useEffect(() => {




    const onUpdateLiveEvent = async ({ live_id, data_id, report_id, label,new_report_id }) => {
      // await  getLives(live_id,data_id);
      // return ;
      console.log("onUpdateLiveEvent", live_id, data_id, label, _lives);
      if (!live_id || !data_id || !label) return;
      var live = live_id ? lives.find(l => l.data_id == parseInt(live_id)) : null;
      if (!live) {
        console.log("onUpdateLiveEvent failed", lives);
        return;
      }
      else {
        console.log("onUpdateLiveEvent success", live)
      }
      var reports = live.reports;
      var i = reports.findIndex(r => r.report_id == parseInt(report_id))
      if (i === -1) {

        reports.push({ report_id, content: { datas: [{ data_id, label: "" + label }] } })
      }
      else {

        var j = reports[i].content.datas.findIndex(d => d.data_id == data_id)
        if (j === -1) reports[i].content.datas.push({ data_id, label: "" + label });
        else {
          reports[i].content.datas[j].label = label
        if(  new_report_id)  reports[i].content.datas[j].report_id = new_report_id
        }
      }


      console.log("Updated ", live, reports)
      setLives([...lives.map(l => live.data_id == l.data_id ? { ...live, reports } : l)]);
      console.log("Updated Lives", lives)
      setNewUpdateData({ data_id });

      //  await  getLives(live,data)

    }

    console.log('refresh socket');
    socket.on('connect', onConnect);
    socket.on('disconnect', onDisconnect);
    //socket.on('newConnection', newConnection);
    socket.on('updateLive', onUpdateLiveEvent);
    return () => {
      socket.off('connect', onConnect);
      socket.off('disconnect', onDisconnect);
      socket.off('updateLive', onUpdateLiveEvent);
      // socket.off('newConnection', newConnection);
    };
  }, [lives, socket]);

  useEffect(() => {
    if (project)
      getLives();
  }, [project,sortGeoHash,slug])


  const reportStart = async (live_id) => {
    var report = await project.api("/report_start", { body: { live_id } })

    if (report?.report_id) {
      setLives(lives.map(l => {
        return l.data_id !== live_id ? l :
          { ...l, reports: [...l.reports, report] }

      }));
      console.log("report Start", live_id, report.report_id, lives)
    }
  }

  const reportStop = async (live_id, report_id) => {

    var live = lives.find(l => l.data_id === live_id)
    live.reports = live.reports.filter(r => r.report_id != report_id)
    setLives(lives.map(l => l.data_id !== live_id ? l : { ...live }));
    var result = await project.api("/report_stop", { body: { report_id } })
    console.log("report Stop", live_id, report_id, lives)

  }


const deleteLive =async(data_id)=>{
if( window.confirm("Are you sure you want to delete"))
{
  var result = await project.api("/set_show", { body: { data_id,is_live:false,deleteData:true } })
 await  getLives();
}
}

const resetLive =async(data_id)=>{

    var result = await project.api("/show_reset", { body: { data_id } })
   await  getLives();
  
  }
  

const updateShow =async(show)=>{

    var result = await project.api("/update_show", { body: show })
   await  getLives();
  
  }

  const updateReport =async(report)=>{

    var result = await project.api("/update_report", { body: report })
   await  getLives();
  
  }
  
  const updateData = async (data, live, report) => {
if(!report )
{
setShowLoader(true)

  console.log("updateData",data,live)
  var result = await project.api("/update_data", { body: { data_id: data.data_id, label: data.label, live_id: live.data_id} })
  await getLives();
setShowLoader(false);
  return 
}
    var i = report.content.datas.findIndex(d => d.data_id == data.data_id)
    if (i === -1) report.content.datas.push(data);
    else report.content.datas[i] = data


    setLives(lives.map(l => {
      return l.data_id !== live.data_id ? l :
        { ...l, reports: l.reports.map(r => r.report_id !== report.report_id ? r : report) }

    }));


    var result = await project.api("/report_add", { body: { data_id: data.data_id, label: data.label, live_id: live.data_id, report_id: report.report_id } })
    //await  getLives(live,data);

  }




  async function getLives(live_id, data_id) {

    if (!live_id) {
     
      var body = { slug }
      if(owner_id)
      {
        body ={ owner_id}
      }
    else  if (currentGeoHash) {
        var gps = Geohash.decode(currentGeoHash);
        body = { ...body, sortGeoHash, currentGeoHash, lat: gps?.lat, lng: gps?.lon }
      }
      else if(sortGeoHash){
        body = { ...body, sortGeoHash, lat:43.4121393 , lng: 3.3016693 }
    }
    

      _lives = await project.api("/get_lives", { body })
      console.log("before", _lives);
      if(!sortGeoHash) _lives.sort((a, b) => a.path < b.path ? -1 : 1)
      setLives(_lives ?? []);
      console.log("after", lives);

    }
    else if (!data_id) {
      var result = await project.api("/get_lives", { body: { data_id: live_id } })
      _lives = lives.map(l => live_id == l.data_id ? result : l)
      setLives(_lives);
    }
    else {
      var result = await project.api("/get_data/:data_id", { args: { data_id: data_id } })

      var live = lives.find(l => live_id == l.data_id)
      live.content.datas = live.content.datas.map(d => d.data_id == data_id ? result : d)
      _lives = lives.map(l => live_id == l.data_id ? live : l)
      setLives(_lives);
    }
    if (    slug || true && !owner_id )   window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    setNoLive( slug && _lives.length==0)
  }

  return (
    <> { showLoader &&  <Loader/>}
    {showUser && <UserProfile    project={project} user={showUser}    close={()=>setShowUser(null)}  />}
      <div className="w-screen  flex justify-start items-center flex-col  bg-zinc-950">
        <Header  enabledMenu={(value)=> setDisabled(value) }  setAuth={setAuthUser}  minimized={ !!slug && !noLive} >
          {
          owner_id ?
          
          <span className='px-4 text-base font-bold italic'>MES MATCHS</span>
          :
          <FontAwesomeIcon icon={fa.faLocationDot} className={' text-color  text-white  text-2xl px-8 ' + (sortGeoHash ? " " : " opacity-25")} onClick={() => setSortGeoHash(!sortGeoHash)} /> }</Header>

        <div    disabled={disabled} className={ " w-full  relative min-h-[calc(100dvh)]   flex flex-col   transition-all  "   +(slug ? "mt-0":" pt-20") }>

         
            {lives && lives.map(live =>
          {
                if(live.show_id)  return  <DataLive className=" scale-95 bg-zinc-950  rounded-xl border border-zinc-700 " setShowUser={setShowUser} updateReport={updateReport} updateShow={updateShow} deleteLive={deleteLive} resetLive={resetLive}  owner_id={owner_id} show={live}  key={live.data_id} currentGeoHash={currentGeoHash}  reportStart={reportStart} reportStop={reportStop} updateData={updateData} newUpdateData={newUpdateData} 
                cover={ sortGeoHash && lives.find(l=>  l.design_id &&   live.path.split(l.path).length>1    )}
                />


                if(!live.show_id && live.design_id && !sortGeoHash)  
                return  <DataLive className=" scale-95 bg-zinc-950  rounded-xl border border-zinc-700 " show={live} key={live.data_id} />

                
              })
            }
{
  noLive && 
  <div    className='flex flex-row w-full h-full pt-80 p-6 text-3xl font-bold  items-center justify-center text-white'>   404 NOT FOUND </div>
}
{
  owner_id   && lives   && 
  <Link to="/setmatch" ><div    className='flex flex-row p-6 w-full h-full   text-3xl font-bold  items-center justify-center text-white'>
    <div className='flex flex-row w-full h-full  p-6 bg-slate-700 rounded-xl text-xl font-bold  items-center justify-center text-white'> CRÉER UN MATCH</div> </div></Link>
}



        </div>


       {    !slug &&    <Footer />}

      </div>

    </>
  );
}

export default Live;
