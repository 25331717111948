

import {  useContext, useMemo, useState } from 'react';
import ButtonApi from './ButtonApi';
import DataLabel from './DataLabel';
import SwitchButton from './SwitchButton';
import { MfctContext } from '../context';


function getRandomHexColor() {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}



function ChooseNature({setNature, natures, updateNatures,dataSpecifique }) {

  const { project} = useContext (MfctContext);
const [isCollection,setIsCollection] = useState(false);
const [isReference,setIsReference] = useState(false);
const [specificity_id,setSpecificityId] = useState();

  const add_nature = async()=>{
  
    var label = document.getElementById("new_nature_label").value;

    document.getElementById("new_nature_label").value="";
    if (label) {
  
      var result = await project.api("/add_nature", { body: { label,color : getRandomHexColor() , specificity_id } })
      console.log(result)
      await updateNatures();
    }
  
  }




  const delete_nature = async(nature)=>{
  

    if (window.confirm('Are you sure to dele the nature')) {
  
      var result = await project.api("/delete_nature/:nature_id", { args: { nature_id:nature.nature_id} })
      console.log(result)
      await updateNatures();
    }
  
  }



  return (      natures && <div className=" border-1 p-1  w-full flex flex-col" >
    <div  className=" border-1 p-1  w-full flex flex-row"  >
<button  className={" border-1 p-1 px-2  mx-2 rounded-full " +( isCollection ? " bg-green-500 text-white ": " ") } onClick={()=>setIsCollection(!isCollection)}>  Collection S </button>
<button  className={" border-1 p-1   px-2 mx-2 rounded-full " +( isReference ? " bg-green-500 text-white ": " ") }onClick={()=>setIsReference(!isReference)}>  Référence *</button>
    </div>
    {
      natures.map(nature =>

        <div className=" text-xs p-1  w-full flex flex-row" key={nature.nature_id}>
          <span className=' p-1 rounded border-4 text-white font-bold mx-1 w-full' style={ isCollection? { background: "white", color: nature.color ?? "#000"  ,borderColor:nature.color ?? "#000"}: {color:"white", background: nature.color ?? "#000" ,borderColor:nature.color ?? "#000"} } onClick={()=>setNature(nature,isCollection,isReference)}>
            
            {   (nature.specificity_id ?  nature.specificity_id +" / ":"")   +  nature.label +( isCollection? "S":"") +( isReference? " *":"")}</span>
         
   
   
   <button onClick={()=>delete_nature(nature)}    className=' bg-red-500 text-white p-2 rounded-full'> x </button>
    </div>

      )
    }


<div className='flex flex-row w-full justify-between items-center'>
    <span>Ajouter  </span>
    <input id="new_nature_label" className='flex-grow p-2 mx-2 border-zinc-300 border rounded-lg ' placeholder='nouvelle nature '/> 
    { dataSpecifique && dataSpecifique.data_id  &&  <DataLabel  data={dataSpecifique}  childrenOnLeft={true}  natures={[dataSpecifique.nature]}  nonature={true}  ><SwitchButton  value={ specificity_id === dataSpecifique.data_id  }  classNameBarOn={" bg-green-600"} changeValue={(value)=> { setSpecificityId( value ?    dataSpecifique.data_id  :null )   }}    /></DataLabel>   } 
        <ButtonApi endpoint={`/add_nature`} title={" + "} callback={add_nature} project={project} />
        
        </div>



   
    </div>

);
}

export default ChooseNature;
