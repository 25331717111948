

import * as fa from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  useContext, useEffect, useMemo, useState } from 'react';
import { projectInit, setToken, getAuthUser } from '../service/mftc-api';
import ButtonApi from './ButtonApi';

import { BrowserRouter, Link, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { MfctContext } from '../context';


function    Header ({ children, enabledMenu  , setAuth ,minimized})  {
  const navigate = useNavigate();
const { project,authUser,setAuthUser} = useContext(MfctContext)

  const location = useLocation();

  
const [ isMenuDisplayed,setIsMenuDisplayed]= useState(false)





useEffect(()=>{
if(enabledMenu)enabledMenu(isMenuDisplayed)
},[isMenuDisplayed])

const logout = async () => {

  setToken();
  setAuthUser(null);
  if(setAuth) setAuth(null);
  setIsMenuDisplayed(false);
  navigate("/")
}

  return   <><div className={ "fixed text-white font-bold p-0 px-0  flex justify-start  bg-zinc-950 z-20  transition-all left-0  overflow-hidden w-full  h-20 rounded-br-none  items-center " +(minimized ? "  -top-20 ":" top-0  ")    }>
  <FontAwesomeIcon onClick={()=>setIsMenuDisplayed(true)} icon={fa.faBars} className={ 'text-white text-xl   mr-1  px-9 py-8  ' } />
        <Link to="/"   className='text-2xl   italic font-bold'  style={ {} }  >    <span>Live</span><span>my</span><span  className='text-red-700 ' >Game</span><span className='text-red-700 text-3xl mb-4' >!</span></Link>

<div   className=' flex flex-row justify-end  flex-grow  '>{children}</div>
      </div>
      {  true &&
        <><div className={'fixed top-0 left-0 z-30 bg-black blur-sm w-screen h-screen transition-all  duration-500 '+ (isMenuDisplayed?' bg-opacity-50 ':' hidden  bg-opacity-0 ' )}   onClick={ ()=>setIsMenuDisplayed(false)}>
          </div>
          <div className={'fixed z-40 bg-zinc-800  h-screen flex flex-col justify-start p-1 font-bold text-zinc-200 pt-10  transition-all  duration-500  top-0 '+ (isMenuDisplayed?' left-0  ':' -left-full ' )}>
        {  authUser ?
         <> <div  className='p-4  flex flex-col items-center justify-center '  > 
          <div  className='text-white rounded-full bg-black w-20 h-20 justify-center items-center flex ' >
         
  <img src={ project?.urlImage("img_" +authUser.user_id )    }    className='  w-20 h-20 rounded-full' />
                      </div>

          <span className='text-white  text-sm '   >@{authUser.username}</span>
           </div> 

           <div  className={ 'p-4 text-sm  border-b border-zinc-500' + (location.pathname=="/" && " bg-zinc-500 text-zinc-950 ")  }  > <Link to='/'> ACCUEIL</Link>  </div>
          <div  className='p-4  text-sm  border-b border-zinc-500'  > <Link to='/profile'> MON PROFIL</Link> </div>
             <div  className='p-4  text-sm  border-b border-zinc-500'  > <Link to='/setmatch'> CRÉER UN MATCH</Link>  </div>
             <div  className={ 'p-4 text-sm  border-b border-zinc-500' + (location.pathname=="/matchs" && " bg-zinc-500 text-zinc-950 ")  } > <Link to='/matchs'>  MES MATCHS  </Link> </div>
             {authUser?.user_id==1 &&             <div  className='p-4  text-sm  border-b border-zinc-500  '  > <Link to='/home'>  MES DATAS</Link>  </div>}
            <div  className='p-4  text-sm  border-b border-zinc-500 hidden'  >  <Link to='/reports'> MES REPORTAGES (3)</Link>  </div>
            <div  className='p-4  text-sm  border-b border-zinc-500 hidden '  > <Link to='/designs'>  MES DESIGNS (10)</Link>  </div>
            <div  className='p-4  text-sm  border-b border-zinc-500 text-red-800 '  > 

             <span onClick={logout}>DÉCONNEXION</span> 
           

             </div>


          </> :
          <>
     
          <div  className='p-4  flex flex-col items-center justify-center text-white text-2xl italic text-center'  > 
 
     <span> Crée ton <span className='text-red-700 '>Live.</span>  </span>
           <span>Rentre dans<br/> le <span className='text-red-700 '>Game!</span> </span>
           </div> 

           <div  className='p-4  border-b border-zinc-500 '  > <Link to='/'>ACCUEIL </Link></div> 
          <div  className='p-4  border-b border-zinc-500 '  ><Link to='/signup'> S'INSCRIRE </Link> </div>        
<div  className='p-4  border-b border-zinc-500 text-zinc-200'  > <Link to='/signin'>SE CONNECTER</Link>  </div></>
}

          </div>
          </>
      }
      
      </>

  //else 
  //return <span >{endpoint} </span>
} 

export default Header;
