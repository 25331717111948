

import { useContext, useEffect, useMemo, useState } from 'react';
import ButtonApi from './ButtonApi';
import DataLabel from './DataLabel';
import FormRef from './FormRef';
import SwitchButton from './SwitchButton';
import { MfctContext } from '../context';


function getRandomHexColor() {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}



function FormNature({ nature, data, natures, updateNatures, replaceNature,getNatures }) {

  const {project} = useContext(MfctContext)
  const [datas, setDatas] = useState([])
  const [level, setLevel] = useState(0)
  const [infonature, setInfoNature] = useState(nature);

  useEffect(() => {

    setInfoNature(nature);

  }, [nature])


  useEffect(() => {
    if (level > 0) getDatas()
  }, [level])





  const add_data = async (data_) => {
    var content = infonature.content;
    if (!content.dynamic_label) content.dynamic_label = [];

    if (data_.nature_id) {
      var relativePath = ""


      if (data.path.indexOf(data_.path)) {
        console.log("C'est dedans",data.path , data_.path )
var dataIn = data_;
        var path = data_.path;
        while (path != data.path) {

          var parts = path.split("-")
          parts.pop();
          path = parts.join("-");


         var dataParent = datas.find(d=>d.path === path)
         if(dataParent.is_collection){
          var childs =  datas.filter(d=>dataParent.data_id === d.parent_id)
          var index = childs.findIndex(d=> d.path ==dataIn.path  )
          relativePath = "."+ dataParent.label+ "["+index+"]" + relativePath
         
          parts.pop();
          path = parts.join("-");
           dataIn = datas.find(d=>d.path === path)
         }
         else
         {
          var natureLabel = natures.find(n=>n.nature_id == dataIn.nature_id).label
          relativePath = "."+ natureLabel + relativePath
          dataIn = dataParent;
         }


    
        }


      }
      else {
     
        var path = data.path;
        console.log("C'est dans les parents",path , data_.path )
        while (path != data_.path) {

          var parts = path.split("-")
          parts.pop();
          path = parts.join("-");

          relativePath += ".PARENT"
        }



      }
      console.log(relativePath)

      data_.relativePath = relativePath

    }

    console.log("add_data", content, data_);
    content.dynamic_label.push({data_id:data_.data_id, label:data_.label,relativePath:data_.relativePath, nature_id:data_.nature_id,natureLabel: data_.natureLabel,natureColor:data_.natureColor   });
    setInfoNature({ ...infonature, content });

  }

  const remove_data = async (i) => {
    var content = infonature.content;
    content.dynamic_label = content.dynamic_label.filter((d, index) => i != index);
    setInfoNature({ ...infonature, content });

  }
  async function getDatas() {
    console.log("getDatas");
    if (!project)
      return

    var result = await project.api("/get_datas_level/:path/:level", { args: { path: data.path, level: data.level + level } })
    console.log("setDatas", result);
    setDatas(result);

  }


  const change_color = () => {

    var color = getRandomHexColor()
    document.getElementById("color_nature").value = color
    setInfoNature({ ...infonature, color });
    replaceNature(infonature);

  }
  const add_text = () => {
    var text = prompt(" Ajouter du texte ", " ");
    add_data({ label: text, natureColor: "#000", natureLabel: "TEXTE" })

  }
  const  RequiredCount=(struct)=>{

    if (struct.is_collection) return 1;
    var structParent = isFromCollection(struct)
    if(  !structParent ) return 1; 
    console.log(structParent);
return parseInt (("0" + structParent.requiredCount)??0);
 
} 

const isFromCollection = (struct)=>{

  var parts = struct.key.split(".")
  parts.pop()
  var path  = parts.join("."); 
  var parent = nature.content.structure[path];
  return parent && parent.is_collection ? parent :null;


}
  const save_infos = async () => {


     
 if (nature.content?.structure){
    var structs = Object.values(nature.content.structure);
    for (var i=0; i<structs.length; i++)
    {
      var struct = structs[i];
    if(document.getElementById(struct.key +"_requiredcount"))
    {
      struct["requiredCount"]=document.getElementById(struct.key +"_requiredcount").value
    }
    if(document.getElementById(struct.key +"_maxcount"))
    {
      struct["maxCount"]=document.getElementById(struct.key +"_maxcount").value
    }
    
    if(document.getElementById(struct.key +"_required"))
    {
      struct["required"]=document.getElementById(struct.key +"_required").checked
    }
    if(document.getElementById(struct.key +"_defaultLabel"))
    {
      struct["defaultLabel"]=document.getElementById(struct.key +"_defaultLabel").value
    }
  
    infonature.content.structure[struct.key]=struct 
    }
  }
  
    infonature.label = document.getElementById("label_nature").value;
    infonature.color = document.getElementById("color_nature").value;

    if(document.getElementById("default_label").value )
    infonature.content = {...infonature.content, defaultLabel: document.getElementById("default_label").value }
    var result = await project.api("/update_nature", { body: infonature })
    console.log(result)
    await updateNatures();


  }

  
  const cancel_infos = async () => {

    await updateNatures();

  }

  return (<div className=" border-1 p-6  w-full flex flex-col" >
    <div className=" border-1 p-1  w-full flex flex-row"  >
      {nature.label}
    </div>




    <span className='text-xs' >Label </span>
    <input id="label_nature" className='w-full p-1 border-2 rounded border-gray-500 border ' placeholder='Saisir Label' defaultValue={nature.label} />
    <span className='text-xs' >Couleur </span>
    <input id="color_nature" className='w-full p-1 border-2 rounded border-gray-500 border ' placeholder='Saisir Couleur' defaultValue={nature.color} />
    <span className='text-xs' >Label par défaut</span>
    <input id="default_label" className='w-full p-1 border-2 rounded border-gray-500 border ' placeholder='Saisir Valeur par défaut' defaultValue={nature.content?.defaultLabel ?? ""} />
      <div className=" flex w-full flex-row items-center justify-start p-1">  <span className='' >Mode Live  </span>   <SwitchButton value={ infonature.is_live_mode  }  changeValue={(value)=>setInfoNature( {...infonature,is_live_mode:value })   }     classNameBarOn={' bg-blue-400 '}/>  <span> { infonature.is_live_mode===1 ? " Oui" :" Non"}</span>
    </div>



    <div className=" flex w-full flex-row items-center justify-start p-1">  <span className='' > Spécificité </span><SwitchButton value={ infonature.is_specificity }  changeValue={(value)=>setInfoNature( {...infonature,is_specificity:value })   }   classNameBarOn={' bg-blue-400 '} />  <span>{ infonature.is_specificity ? " Oui" :" Non"}</span>



    </div>
<span className='text-xs' >Label Dynamique </span>
    <div className=" flex overflow-x-auto text-xs items-start justify-start">
      {(nature.content?.dynamic_label ?? []).map((d, i) =>
        <DataLabel data={d} natures={natures} nonature={true} noRef={true} onClick={() => { remove_data(i) }} />
      )}
    </div>
    <div className='w-full p-1 border-2 rounded border-gray-500 border text-xs'>
      {
        datas.filter(d => !d.is_collection).map(data_ => <span key={data_.data_id}><DataLabel data={data_} natures={natures} nonature={true} onClick={() => add_data(data_)} noRef={true} /></span>)
      }
      {level > 0 && <DataLabel data={{ label: "AJOUTER DU TEXTE" }} nonature={true} onClick={() => add_text()} noRef={true} />}
      {level == 0 && <DataLabel data={{ label: "CHARGER DONNÉES ..." }} nonature={true} onClick={() => setLevel(2)} noRef={true} />}
      {level > 0 && <DataLabel data={{ label: "CHARGER PLUS DE DONNÉES ..." }} nonature={true} onClick={() => setLevel(level+1)} noRef={true} />}
    </div>
    

    {    nature && nature.content?.structure &&   Object.values(nature.content.structure).map((struct,i)=>
    <div className="p-0 flex flex-col w-full "   key={i}  >
    
     {  Array( RequiredCount(struct)   ).fill( struct).map((str,i)=>
      
      <DataLabel data={{...str,  label:str.label ?? natures.find(n=>n.nature_id == struct.nature_id).label + ( !isFromCollection(struct) ? "": " " +  (i+1))    }} nonature={true} natures={natures} key={i}> 
      
      
      
  
      { true && 
     
        <div   className="p-2 flex flex-col w-full border-t border-black border-opacity-10 " >
           { struct.is_collection && <><span className="text-xs">RequiredCount</span> 
            <input type="number" id={str.key +"_requiredcount"} className="text-black w-8 p-1"  placeholder="0" defaultValue={struct.requiredCount} /></>  
      }
                 { struct.is_collection && <><span className="text-xs">MaxCount</span> 
            <input type="number" id={str.key +"_maxcount"} className="text-black w-8 p-1"  placeholder="-" defaultValue={struct.maxCount} /></>  
      }
         { !isFromCollection(struct) && !struct.is_collection && <><span className="text-xs">Required</span>
            <input type="checkbox" id={str.key +"_required"} className="text-black w-6 p-1"    defaultChecked={struct.required} /></>  
      }
               { !struct.is_collection && !struct.is_reference &&  <><span className="text-xs">DefaultLabel</span>
            <input type="text" id={str.key +"_defaultLabel"} className="text-black w-full p-1"  placeholder="Saisir une valeur par défaut" defaultValue={  struct.defaultLabel } /></>  
      }

           </div>
        }
        </DataLabel>
   
      )
     }
    
       </div>)
    }





    <button className="p-1 w-full rounded-full mt-1 text-bleu-500 font-bold" onClick={change_color}> Changer couleur </button>

    <button className="p-1 w-full rounded-full mt-1 text-green-500 font-bold" onClick={save_infos}> Sauver</button>
    <button className="p-1 px-2 rounded-full  text-red-500 font-bold" onClick={cancel_infos} >Annuler</button>


  </div>

  );
}

export default FormNature;
