


const changeBetween = (str, start, newstr, end) => {

  var parts = str.split(start);
  var subparts = parts[1].split(end);
  subparts[0] = newstr;
  parts[1] = subparts.join(end);
  return parts.join(start)

}

const compute_data = (data, formula, datas = []) => {


  var parts = [];
  var completed=true;
  var dataToUpdate = null;
  var labelToUpdate = null;
  console.log("Formula ", formula, datas)
  for (var i = 0; i < formula.length; i++) {
    var element = formula[i];
    console.log(data);
    console.log(element);


    if (element.cursor) {

      const cursor = datas.find(d => d.relativePath == element.cursor.relativePath || d.nature_id == 38)
      console.log("Cursor", element.cursor, cursor, datas)
      if (cursor) {
        var index = Math.max(0, (parseInt(cursor.label == "-" ? "0" : cursor.label) - 1))
        console.log("Cursor", index);

        element.relativePath = changeBetween(element.relativePath,
          "." + element.cursor.liste + "[", index, "]")


        console.log(element.relativePath);
      }

    }


    var path = data.path;
    var data_ = data;
var otherdata_ = null;
    if (element.natureLabel == "TEXTE") parts.push(element.label);
    else {
      var fastData = datas.find(d => d.relativePath?.replaceAll(" ", "") == element.relativePath.replaceAll(" ", ""))
      if (fastData) {
        parts.push(fastData.label);
        if(fastData.label=="-") completed=false;
        if (fastData.is_live_mode) dataToUpdate = fastData;
        else labelToUpdate = fastData
      }
      else {
        var pathParts = element.relativePath.split(".");
        pathParts.shift();
        if (pathParts.length == 0)
         { parts.push(data.label);
          if(data.label=="-") completed=false;
        }
           else {


          while (data_ && pathParts.length > 0) {
            console.log(pathParts);
            var pp = pathParts.shift();
            console.log(pp);
            if (pp === "PARENT") {
              var p = path.split("-");
              p.pop();
              path = p.join("-");

            }
            else {

              if (pp.indexOf("[") > 0) {
                var label = pp.split("[")[0].trim();

                var datac = datas.find(d => d.parent_id == data_.data_id && d.label.trim() === label.trim() && d.is_collection);
                if (!datac) {
                  console.log("not found 1", data, element.relativePath, datas)
                  data_ = null; break;
                }

                var index = parseInt(pp.split("[")[1].replace("]", ""));
                var datas_ = datas.filter(d => d.parent_id === datac.data_id);
                if (datas_.length == 0) {
                  console.log("not found 2", element.relativePath)
                  data_ = null; break;
                }
                data_ = datas_[index];


              }
              else {

                var datac = datas.find(d => d.parent_id == data_.data_id && d.nature_id === element.nature_id);
                if (!datac) {
                  console.log("not found 3", element.relativePath)
                  data_ = null; break;
                }
                data_ = datac;

              }

            }
          }
          if (data_) {
            parts.push(data_.label);
           if(data_.label=="-") completed=false;
            if (data_.is_live_mode) dataToUpdate = data_;
            else  labelToUpdate = data_
          }
          else {
            
            completed=false;
           parts.push("...")
        }
        }

      }
    }

  }
  return { text: parts.join(""), data_: dataToUpdate, label_: dataToUpdate ? null : labelToUpdate ,completed};

}

export { compute_data };