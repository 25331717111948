import { useMemo, useState,useEffect } from "react";
import DataLabel from "./DataLabel";
import ChooseNature from "./ChooseNature";
import Geohash from "latlon-geohash";
import { MapContainer, TileLayer,  Popup,Marker, useMap ,useMapEvents} from 'react-leaflet'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faMapLocation, faMapLocationDot } from "@fortawesome/free-solid-svg-icons";
import 'leaflet/dist/leaflet.css';
import iconMarker from "leaflet/dist/images/marker-icon.png";
import iconRetina from "leaflet/dist/images/marker-icon-2x.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";

import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import L from "leaflet";
L.Icon.Default.mergeOptions({
  iconRetinaUrl: iconRetina,
  iconUrl: iconMarker,
  shadowUrl: iconShadow,
});
function FormMap({valideGeoHash, geohash,close}){


const [newGeohash,setNewGeohash]= useState(geohash);
const [currentPosition,setCurrentPosition]= useState(  { lat: 48.8575392, lng: 2.3395407  } );




useEffect(()=>{


  if( !geohash && isMobile)
  navigator.geolocation.getCurrentPosition(function(position) {
  
  
  console.log("position ",position)
    console.log("Latitude is :", position.coords.latitude);
    console.log("Longitude is :", position.coords.longitude);
    if(!currentPosition)
  setCurrentPosition( { lat: position.coords.latitude, lng: position.coords.longitude   }  )
  
  
  });




},[geohash])


const center = useMemo(()=>{
  console.log("GPS","currentPosition",currentPosition)
  if(geohash)
  {
  
  var g =Geohash.decode(geohash);
  
  return {lat : g.lat, lng : g.lon};
  }
  else return currentPosition
  },[geohash,currentPosition])



const gps = useMemo(()=>{
console.log("GPS","currentPosition",currentPosition)
if(newGeohash || geohash)
{

var g =Geohash.decode(newGeohash?? geohash);

return {lat : g.lat, lng : g.lon};
}
else return null
},[newGeohash,geohash])


  function LocationMarker({position}) {

    const map = useMapEvents({

      click(e) {

    if(    valideGeoHash)
setNewGeohash(  Geohash.encode( e.latlng.lat,e.latlng.lng,12  ) );
         

      },
 
    })
  
    return position === null ? null : (
      <Marker position={position}>
      </Marker>
    )
  
    }

  const  myNavFunc=useMemo(()=>{
      // If it's an iPhone..
   return  ( (navigator.userAgent.indexOf("iPhone") != -1) 
          || (navigator.userAgent.indexOf("iPod") != -1)
          || (navigator.userAgent.indexOf("iPad") != -1))
       && `http://maps.apple.com/?sll=${gps.lat},${gps.lng}&t=s&z=10` 
  },[gps])
  const  myNavFuncGMaps=useMemo(()=>{
    // If it's an iPhone..
 return  `https://www.google.com/maps/search/?api=1&query=${gps.lat}%2C${gps.lng}`;
},[gps])

  return <div    className="fixed w-screen h-screen   bg-black flex flex-col items-center justify-center bg-opacity-70 z-40 " >
  <FontAwesomeIcon icon={faClose }    className="fixed  top-0 right-0 p-4 m-2 rounded-full bg-zinc-800 text-xl text-white z-50 "    onClick={close}/>
    <div  className="fixed top-0 left-0 w-full h-full  border-4 border-white overflow-hidden"   >
    <MapContainer style={{ height: "100%", minHeight: "100%" }}  className="w-full h-full"
      center={center}
      zoom={center ? 13: 0}
zoomControl={true}
      scrollWheelZoom={true}>
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <LocationMarker position={gps }  />
    </MapContainer></div>
    <div className=" flex flex-row w-full h-20 items-center justify-center fixed bottom-0 left-0 z-50">
  { newGeohash &&  valideGeoHash && <div className=" m-2 bg-green-700 font-bold text-white p-2 rounded "   onClick={()=>{
    var g =Geohash.decode(newGeohash?? geohash);
    valideGeoHash(newGeohash,g.lat,g.lon);close()} } > VALIDER  </div>}
    { geohash &&  myNavFunc && <a href={myNavFunc} ><div className=" m-2 bg-white font-bold text-zinc-700 p-2 rounded "    > <FontAwesomeIcon icon={faMapLocationDot }    className=" "  /> Maps     </div></a>}
      { geohash &&   <a href={myNavFuncGMaps} ><div className=" m-2 bg-white font-bold text-zinc-700 p-2 rounded "    >   <FontAwesomeIcon icon={faMapLocationDot }    className=" "  /> Google   </div></a>}
    </div>
    </div>



}


export default FormMap;