
import './App.css';

import {
  BrowserRouter,
  Switch,
  Routes,
  Route,
  Link,
  useParams
} from "react-router-dom";
import Home from './Home';
import Live from './Live';
import Auth from './Auth';
import SetMatch from './SetMatch';
import Profile from './Profile';
import Designs from './Designs';
import Matchs from './Matchs';
import Reports from './Reports';
import { useEffect, useMemo, useState } from 'react';
import { MfctContext } from './context';
import { getAuthUser, projectInit } from './service/mftc-api';


function App() {

const [authUser,setAuthUser] = useState(getAuthUser())
const [project,setProject]=useState()

async function getProject() {

  console.log("getProject", process.env.REACT_APP_MFTC_SERVER_URL, process.env.REACT_APP_PROJECT_NAME)
  const p = await projectInit(process.env.REACT_APP_MFTC_SERVER_URL, process.env.REACT_APP_PROJECT_NAME)
  console.log('project', p);
  setProject(p);


}

const value = useMemo(()=>{

 return {
  authUser,
  setAuthUser,
  project,
  getProject
 } 
},[authUser,project])

useEffect (() => {
  getProject();
}, [authUser])


  return (
    <MfctContext.Provider value={value}>
    <BrowserRouter>
      <Routes>
        <Route path="" element={<Live/>} />
        <Route path="/live/:slug" element={<Live    displayOne={true} />} />
        <Route path="/data/:slug" element={<Home/>} />
        <Route path="/signin" element={<Auth  mode={"signin"}  />} />
        <Route path="/signup" element={<Auth  mode={"signup"} />} />
        <Route path="/home" element={<Home/>} />
        <Route path="/profile" element={<Profile/>} />
        <Route path="/setmatch" element={<SetMatch/>} />
        <Route path="/designs" element={<Designs/>} />
        <Route path="/matchs" element={<Matchs/>} />
        <Route path="/reports" element={<Reports/>} />
        <Route path="/path/:path" element={<Home/>} />
      </Routes>
    </BrowserRouter>
    </MfctContext.Provider>
  );
}

export default App;
